import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { UiService } from '@app/core/services/ui.service';
import { LoaderService } from "./loader.service";
import { AuthService } from "./core/services/auth.service";
import { environment } from '@env/environment';
import { AgentConfirmationService } from "./core/services/agent-confirmation.service";
import { DataService } from "./core/services/data.service";
import { GoogleTagManagerService } from "./shared/google-tag-manager.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit{
  title = "bop-frontend";

  appForm:FormGroup;
  

  constructor(public fb: FormBuilder,public router: Router, public uiService: UiService,public dataService:DataService, public LoaderService: LoaderService, public authService: AuthService, public agentConfirmationService: AgentConfirmationService,public gtmService:GoogleTagManagerService) { }

  ngOnInit() {
    this.gtmService.setupGoogleTagManagerPageEvents();
    
  }

  createForm() {
    this.appForm = this.fb.group({
      rate : new FormControl("Y"),
      draft : new FormControl("N"),
      fullModel:new FormControl("Y"),
      quickQuote:new FormControl("N"),
    })
  }

  navigateToCoverall() {
    window.open(environment.coverallURL + "?authtoken=" + encodeURIComponent(sessionStorage.getItem("coverallToken")), "_blank");
    sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
    location.reload();
  }
  onHasMultiLocationClose(){
    sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
    location.reload();
  }

  closeAction() {
    this.uiService.quotedWithReferrals = false;
    sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
    location.reload();
  }

  declineClose() {
    this.uiService.declineFlag = false;
    location.reload();
  }
  declineSqFootageClose(){
    this.uiService.SqftDeclineFlag = false;
    location.reload();
  }
  removeDeclinedLocation(){
    this.uiService.removeLocation.next(this.uiService.declinedLocations);
    this.uiService.declineFlag = false;
  }

  exitQuoteFromPopup() {
    this.uiService.exitQuoteFlag = false;
    location.reload();
  }

  invokeMarketplace() {
    this.uiService.bpLocationNormalizedMailAddressFlag = false;
    this.uiService.bpLocationNormalizedMailAddressNotMatchFlag = false;
    this.uiService.callMarketplace('1B', this.uiService.smallContractorInd, false);
  }


  // Copy Location

 

  CopyLocationsList = [
    {
      Street:"8 Park Ave",
      City:"Worcester",
      State:"MA",
      Zip:"01606",
      IsSelected:false,
      IsDisabled:false
    },
    {
      Street:"14th Main St",
      City:"Worcester",
      State:"MA",
      Zip:"01605",
      IsSelected:false,
      IsDisabled:false
    },
    {
      Street:"55 Lincoln St",
      City:"Worcester",
      State:"MA",
      Zip:"01606",
      IsSelected:false,
      IsDisabled:false
    }
  ]

  _doSelectLocation(location){
    this.uiService.mailingLocationSelected =   this.uiService.mailingAddressPopupList.filter((obj, index)=>{
      return obj.IsSelected === true
    })
    console.log(this.uiService.mailingLocationSelected,"Selected")
    if(this.uiService.mailingLocationSelected.length > 0){
     
      this.uiService.mailingAddressPopupList.forEach((ele)=>{
        ele.IsDisabled = ele.IsSelected ? false: true
      })
    }
    else {
      this.uiService.mailingAddressPopupList.forEach((ele)=>{
        ele.IsDisabled = false;
      })
    }
  }

  onConfirmLocation(){
    if(this.uiService.mailingLocationSelected?.length>0){
      this.uiService.confirmedMailingLocation=this.uiService.mailingLocationSelected[0];
      this.uiService.cMailingAddress.next(this.uiService.mailingLocationSelected[0])
      console.log(this.uiService.confirmedMailingLocation,"confirmed mailing Location from app.ts")
    }
  }
  onClose(){
    if(!this.uiService.confirmedMailingLocation){
      this.uiService.cMailingAddress.next('close')
    }else{
      this.uiService.mailingAddressPopupList.forEach((d,index)=>{
        if(d.id==this.uiService.confirmedMailingLocation.id){
          this.uiService.mailingAddressPopupList[index].IsSelected=true;
          this.uiService.mailingAddressPopupList[index].IsDisabled=false;
        }else{
          this.uiService.mailingAddressPopupList[index].IsSelected=false;
          this.uiService.mailingAddressPopupList[index].IsDisabled=true;

        }
      })
    }
  }

  _doRemoveDeclinedBuildings() {
    this.uiService.RemoveDeclinedBuildings.next('SqlDeclined');
    this.uiService.SqftDeclineFlag= false;
  }
  _doRemoveDeclinedLocation(){
    this.uiService.removeDeclinedLocationOnSquareFootageDeclination.next('sqFootageDecline');
    this.uiService.SqftDeclineFlag=false
  }
}
