<div class="main-contactor">
  <div class="container-contractor">
    <div class="block-contractor">
      <div class="section-contractor">
        <div class="error-box" *ngIf="uiService.hasRatingErrors">
          <img class="error-icon" src="./assets/img/icon-error.png" alt="Error">
          <div class="error-message">
            An error has occurred in the calculation process, please contact your underwriter for assistance.
            <div class="error-text">
              {{uiService.ratingErrorMessage}}
            </div>
          </div>
        </div>
        <div class="quote-contactor" *ngIf="uiService.quoteNumber">
          <div class="inner-title-contractor">
            Quote Summary: {{uiService.quoteNumber}}
          </div>
          <div class="quote-row" *ngIf="AnnualPremiumAmount">
            <div class="price-row">
              <div class="price">
                <p class="price-row-dollar">$</p>
                <p class="price-row-value">{{AnnualPremiumAmount}}</p>
                <p class="annually">Annually</p>
              </div>
            </div>
          </div>

          
          <!-- <div class="quote-row" >
            <div class="price-row">
              <div class="price">
                <p class="price-row-dollar">$</p>
                <p class="price-row-value">1150</p>
                <p class="annually">Annually</p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="coverages1and2 no-default-height">
          <div class="coverages1 no-default-height">

         
          <div class="coverages2-row1">
            <p class="location-property-row1-title">Liability Coverages</p>
          </div>
          <div class="coverages1-coverage1">
            <div class="coverage1-row-liab">
              <p class="liab-limits">Liability Limits</p>
              <p class="liab-limits-value">{{LiabilityLimits}}</p>
            </div>
          </div>
          <div class="coverages1-coverage1">
            <div class="coverage1-row">
              <p class="liability-limits">Medical Expenses</p>
              <p class="liability-limits-value">{{MedicalExpenses}}</p>
            </div>
          </div>
          <div class="coverages1-coverage1">
            <div class="coverage1-row">
              <p class="liability-limits">Products and Completed Aggregate Limit</p>
              <p class="liability-limits-value">{{ProductsCompletedAggregateLimit}}</p>
            </div>
          </div>
          <div class="coverages1-coverage1">
            <div class="coverage1-row">
              <p class="liability-limits">Liability General Aggregate Limit</p>
              <p class="liability-limits-value">{{LiabilityGeneralAggregateLimit}}</p>
            </div>
          </div>
      
        
        </div>
        <div class="coverages2-coverages no-default-height">
          <div class="coverages2-row1">
            <p class="location-property-row1-title">Policy Coverages</p>
          </div>

          <div class="coverages2-coverage" *ngIf="HiredAutoCoverage">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Non-Owned/Hired Auto Coverage</p>
              <p class="liability-limits-value">{{NonOwnedHiredAutoCoverage}}</p>
            </div>
          </div>

          <div class="coverages2-coverage">
            <div class="coverages2-coverage-row1" *ngIf="EmpPracLiability">
              <p class="liability-limits">Employment Practices Liability</p>
              <p class="liability-limits-value">{{EmploymentPracticesLiability}}</p>
            </div>
          </div>

          <div class="coverages2-coverage" *ngIf="CyberCoverLimit">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Cyber {{cCyberOptionValue}}</p>
              <p class="liability-limits-value">{{CyberLite}}</p>
            </div>
          </div>

         

          <div class="coverages2-coverage" *ngIf="cBlgExtRepCcFlag">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Building Extended R/C</p>
              <p class="liability-limits-value">{{CExtRpCcOptions}}%</p>
            </div>
          </div>

          <div class="coverages2-coverage" *ngIf="ContractorEnhancer">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Contractor Enhancer</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>
          <div class="coverages2-coverage" *ngIf="EnhanceEndorsement">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Enhancer Endorsement</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>
          <div class="coverages2-coverage" *ngIf="EnhancePlusEndorsement">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Enhancer Plus Endorsement</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>
          
          <div class="coverages2-coverage" *ngIf="CondLiabEndtLmtFlag">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Condo D&O Coverage </p>
              <p class="liability-limits-value">{{CondLiabEndtLmt}}</p>
            </div>
          </div>
          <div class="coverages2-coverage" *ngIf="(uiService.convenience09331Flag || uiService.convenience54136Flag || RestaurantFlag || uiService.mercantileRiskBewargeGorceryStoreFlag) && uiService.liquorLiabilityCoverageSelectedFlag=='Y'">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Liquor Liability</p>
              <p class="liability-limits-value">{{LiabilityLimits}}</p>
            </div>
          </div>
       
          <div class="coverages2-coverage" *ngIf="ContractorToolsandEquipment">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Contractor Tools and Equipment</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>

          <div class="coverages2-coverage" *ngIf="SnowPlowLiability">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Snow Plow Liability</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>
       
          <div class="coverages2-coverage" *ngIf="FuneralProfessionalCoverage">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Funeral Professional Coverage (Funeral)</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>
         


          <div class="coverages2-coverage" *ngIf="uiService.onBlanketOngoingFlag=='Y' || uiService.onBlanketCompletedFlag=='Y' ">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">Blanket Additional Insured</p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>

          <div class="coverages2-coverage" *ngIf="WaiverofSubrogation">
            <div class="coverages2-coverage-row1">
              <p class="liability-limits">
                Waiver of Subrogation
              </p>
              <p class="liability-limits-value">Inc.</p>
            </div>
          </div>

          <div class="coverages2-coverage" *ngIf="PrimaryNoncontributory">
              <div class="coverages2-coverage-row1">
                <p class="liability-limits">Primary Non-contributory</p>
                <p class="liability-limits-value">Inc.</p>
              </div>
            </div>

            <div class="coverages2-coverage" *ngIf="DataService.BPContractorsEndorsement" >
              <div class="coverages2-coverage-row1">
                <p class="liability-limits">Contractors Installation Coverages</p>
                <p class="liability-limits-value">Inc.</p>
              </div>
            </div>
        </div>
        </div>
        <hr class="line" style="order: 0;" />
          <div *ngFor="let x of DataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding ;let i=index">

            <div class="location no-default-height">
              <div class="location-row1 no-default-height">
                <p class="location-row1-title">Location {{x.locationId}}/Building {{x.buildingId}}</p>
              </div>
              <div class="coverages1and2">
    
             
           <div class="coverages1">
    
           
                <div class="location-row1 no-default-height">
                  <p class="location-property-row1-title">Property Coverages</p>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Building Coverage</p>
                    <p class="liability-limits-value">{{x.BPBuildingCoverage?(x.BPBuildingCoverage.BuildingLimitInsurance | convertDollar):zeroDollar}}</p>
                  </div>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Business Personal Property</p>
                    <p class="liability-limits-value">{{x.BPClassifications.BusinssPersonalPropLmt?(x.BPClassifications.BusinssPersonalPropLmt | convertDollar):zeroDollar}}</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.photography=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Photography Endorsement</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.brbBeutPrsEnhPrfLb=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Barbers/Beautician Prof. Coverage</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.veterinarProfLiab=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Veterinarian Coverage </p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Deductible</p>
                    <p class="liability-limits-value">${{x.OptionalPropertyDedu}}</p>
                  </div>
                </div>
              </div>
              <div class="coverages1">
    
             
                <div class="location-row1 no-default-height">
                  <p class="location-property-row1-title">Location Coverages</p>
                </div>
                <div class="coverages2-coverage">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Damage to Premises - Rented</p>
                    <p class="liability-limits-value">{{x.dmgPremRentYouLimit | convertDollar}}</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="DataService.businessOwners.BPLocations[x.locationId-1].BPInlineOBJ.apartmentBldEnd=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Apartment Buildings Endorsement</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="DataService.businessOwners.BPLocations[x.locationId-1].BPInlineOBJ.restEndorsement=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Restaurant Endorsement </p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
              </div>
            </div>
           
            </div>
          </div>
          <!-- <div *ngFor="let x of LocationTwoBuildings ;let i=index">

            <div class="location no-default-height">
              <div class="location-row1 no-default-height">
                <p class="location-row1-title">Location {{x.locationId}}/Building {{i+1}}</p>
              </div>
              <div class="coverages1and2">
    
             
           <div class="coverages1">
    
           
                <div class="location-row1 no-default-height">
                  <p class="location-property-row1-title">Property Coverages</p>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Building Coverage</p>
                    <p class="liability-limits-value">{{x.BPBuildingCoverage?(x.BPBuildingCoverage.BuildingLimitInsurance | convertDollar):zeroDollar}}</p>
                  </div>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Business Personal Property</p>
                    <p class="liability-limits-value">{{x.BPClassifications.BusinssPersonalPropLmt?(x.BPClassifications.BusinssPersonalPropLmt | convertDollar):zeroDollar}}</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.photography=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Photography Endorsement</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.brbBeutPrsEnhPrfLb=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Barbers/Beautician Prof. Coverage</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.veterinarProfLiab=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Veterinarian Coverage </p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Deductible</p>
                    <p class="liability-limits-value">${{x.OptionalPropertyDedu}}</p>
                  </div>
                </div>
              </div>
              <div class="coverages1">
    
             
                <div class="location-row1 no-default-height">
                  <p class="location-property-row1-title">Location Coverages</p>
                </div>
                <div class="coverages2-coverage">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Damage to Premises - Rented</p>
                    <p class="liability-limits-value">{{x.dmgPremRentYouLimit | convertDollar}}</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="DataService.businessOwners.BPLocations[1].BPInlineOBJ.apartmentBldEnd=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Apartment Buildings Endorsement</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="DataService.businessOwners.BPLocations[1].BPInlineOBJ.restEndorsement=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Restaurant Endorsement </p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
              </div>
            </div>
           
            </div>
          </div> -->
          <!-- <div *ngFor="let x of LocationThreeBuildings ;let i=index">

            <div class="location no-default-height">
              <div class="location-row1 no-default-height">
                <p class="location-row1-title">Location {{x.locationId}}/Building {{i+1}}</p>
              </div>
              <div class="coverages1and2">
    
             
           <div class="coverages1">
    
           
                <div class="location-row1 no-default-height">
                  <p class="location-property-row1-title">Property Coverages</p>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Building Coverage</p>
                    <p class="liability-limits-value">{{x.BPBuildingCoverage?(x.BPBuildingCoverage.BuildingLimitInsurance | convertDollar):zeroDollar}}</p>
                  </div>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Business Personal Property</p>
                    <p class="liability-limits-value">{{x.BPClassifications.BusinssPersonalPropLmt?(x.BPClassifications.BusinssPersonalPropLmt | convertDollar):zeroDollar}}</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.photography=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Photography Endorsement</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.brbBeutPrsEnhPrfLb=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Barbers/Beautician Prof. Coverage</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="x.BPClassifications.veterinarProfLiab=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Veterinarian Coverage </p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages1-coverage1">
                  <div class="coverage1-row">
                    <p class="liability-limits">Deductible</p>
                    <p class="liability-limits-value">${{x.OptionalPropertyDedu}}</p>
                  </div>
                </div>
              </div>
              <div class="coverages1">
    
             
                <div class="location-row1 no-default-height">
                  <p class="location-property-row1-title">Location Coverages</p>
                </div>
                <div class="coverages2-coverage">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Damage to Premises - Rented</p>
                    <p class="liability-limits-value">{{x.dmgPremRentYouLimit | convertDollar}}</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="DataService.businessOwners.BPLocations[2].BPInlineOBJ.apartmentBldEnd=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Apartment Buildings Endorsement</p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
                <div class="coverages2-coverage" *ngIf="DataService.businessOwners.BPLocations[2].BPInlineOBJ.restEndorsement=='Y'">
                  <div class="coverages2-coverage-row1">
                    <p class="liability-limits">Restaurant Endorsement </p>
                    <p class="liability-limits-value">Inc.</p>
                  </div>
                </div>
              </div>
            </div>
           
            </div>
          </div> -->
       
       
      


        <div class="feedback-box-contractor" *ngIf="uiService.referralIndicator == 'Y'">
          <img class="warning-icon" src="./assets/img/icon-warning.png" alt="Warning">
          <div class="fb-content-contractor">
            <div class="fb-content1">Submission requires additional underwriter review:</div>
            <div class="fb-content2" *ngFor="let message of uiService.referralMessages">
              &bull; {{message}}
            </div>
          </div>
        </div>

        <div class="coverages4">
          <div *ngIf="!uiService.hasRatingErrors && uiService.referralIndicator != 'Y' " class="textAlignCentre">
            <img class="check-icon" src="./assets/img/icon-check.png">
            <div class="quote-without-referrals-modal-title-text">Almost there!</div>
          <div class="quote-without-referrals-modal-subtitle-text">Quote has been sent to the Policy Administration System and is ready to be customized or purchased.</div>
          </div>
          <button class="proceed-button" (click)="proceedToCoverall();" *ngIf="!uiService.hasRatingErrors">Proceed<mat-icon>keyboard_arrow_right</mat-icon></button>
          <div class="coverages4-row">
            <p class="coverages4-row-text">Need assistance from your Underwriter?</p>
            <div class="coverages4-action-link">
                <mat-icon class="phone_icon">phone_outline</mat-icon>
                <p class="phone">1-800-922-8276</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rectangle-contractor"></div>
</div>
