<div class="main-property">

  <div class="liability">
    <div class="liability-box">
      <app-developer-json-view [myFormGroup]="liabilityForm" [mode]="'liabilityForm'"></app-developer-json-view>
      <form [formGroup]="liabilityForm">
        <div class="liability-sec no-default-height">
          <div class="liability-sec no-default-height">
            <div class="row-frame-h118">
              <div class="section-title">
                <p class="title-text">Liability/Policy Screen</p>
              </div>
              <div class="row-frame-h62">
                <div class="field-box-w276">
                  <label class="field-label-w176 required-field">Liability/Medical Limit</label>
                  <select class="field-input-w276" id="liability" name="type"
                    [formControlName]="contractorConstants.liabMedicalExpensLmt"
                    (change)="onLiabilityChange($event.target.value)">
                    <option class="dropdown-text" style="display:none"></option>
                    <option class="dropdown-text" [value]="liability.name"
                      *ngFor="let liability of processedLiabilityLimitOptions">{{liability.name}}</option>
                  </select>
                </div>
                <div class="field-box-w276" style="gap: 12px;">
                  <label class="field-label-w261">Products and Completed Aggregate Limit</label>
                  <label class="field-value-liab" name="prodCompleteOperAggLmt">{{ prodCompleteOperAggLmt }}</label>
                </div>
                <div class="field-box-liab" style="gap: 12px;">
                  <label class="field-label-w210">Liability General Aggregate Limit</label>
                  <label class="field-value-liab" name="liabMedicalExpAggLmt">{{ liabMedicalExpAggLmt }}</label>
                </div>
                <div class="field-box-w276">
                  <label class="field-label-w276">Medical Expense</label>
                  <div class="toggle-group-w276">
                    <div value="Y" class="toggle-button-left-w138"
                      [ngClass]="medicalExpenseSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                      (click)="onMedicalExpenseClick(true)">$5,000</div>
                    <div value="N" class="toggle-button-right-w138"
                      [ngClass]="medicalExpenseSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                      (click)="onMedicalExpenseClick(false)">$10,000</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row-frame-h62" *ngIf="uiService.blancketFields">
              <div class="field-box-w400">
                <label class="field-label-w176">Blanket Type
                  <div class="tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="tooltiptext">
                      If more individualized Blanket Rating is desired, please make modifications within MAPFREConnect
                    </span>
                  </div>
                </label>
                <select class="field-input-w400" formControlName="blanketType" name="type" required id="blanket-type"
                  (change)="toggleBlanketType()">
                  <option class="dropdown-text" [value]="blanketType.value"
                    *ngFor="let blanketType of dropDownService.blanketTypeOptions">
                    {{ blanketType.name }}
                  </option>
                </select>
              </div>
              <div class="field-box-w276">
                <label class="field-label-w176 ">Blanket Limit</label>
                <input class="field-input-w276" formControlName="blanketLimit" />
              </div>

            </div>
            <div class="row-frame-h62">

              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]) && (uiService.condo69145Flag))||((dataMappingService.businessFlags[BusinessAttribute.OFFICE])&&(uiService.condoOffice60999Flag))">
                <label class="field-label-w276">Condo D & O</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="condoDOSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onCondoDOClick(true)">YES</div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="condoDOSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onCondoDOClick(false)">NO</div>
                </div>
              </div>
              <div class="field-box-w276"
                *ngIf="(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]||dataMappingService.businessFlags[BusinessAttribute.OFFICE]) && condoDOSelectedVal == 'Y'">
                <label class="field-label-w276">Limit</label>
                <select class="field-input-w276" name="type" [formControlName]="contractorConstants.condLiabEndtLmt">
                  <option class="dropdown-text" style="display:none"></option>
                  <option class="dropdown-text" [value]="limit" *ngFor="let limit of dropDownService.condoLimitOptions">
                    {{ limit }}
                  </option>
                </select>
              </div>
              <div class="field-box-w276"
                *ngIf="(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]||dataMappingService.businessFlags[BusinessAttribute.OFFICE]) &&condoDOSelectedVal == 'Y' ">
                <label class="field-label-w276">Deductible</label>
                <select class="field-input-w276" name="type"
                  [formControlName]="contractorConstants.condOffLiabEndtDeduc">
                  <option class="dropdown-text" style="display:none"></option>
                  <option class="dropdown-text" [value]="deductible"
                    *ngFor="let deductible of dropDownService.condoDeductibleOptions">
                    {{ deductible }}
                  </option>
                </select>
              </div>
              
              <div class="field-box-w135"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] && defaultContractorLiabilityDeductibleFlag ">
                <label class="field-label-w125">Liability Deductible</label>
                <label class="field-value-liab-w42">{{liabilityDeductibleLabelValue}}</label>
              </div>
              <div class="field-box-w576" style="order: 3;"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
                <label class="field-label-w576 required-field">Applicant subcontracts 25% or more of the
                  operation?</label>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="applicantSubContSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onApplicantSubContClick(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="applicantSubContSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onApplicantSubContClick(false)">No</div>
                </div>
                <div *ngIf="uiService.applicantSubcontractRequiredFlag" class="alert">
                  Required
                </div>
              </div>

              <div class="field-box-w276"
                *ngIf="((dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS])&&(uiService.processingFuneralService71865Flag))">
                <label class="field-label-w276">Funeral Professional Coverage</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="psFuneralServiceSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsFuneralServiceClick(true)">YES</div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="psFuneralServiceSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPsFuneralServiceClick(false)">NO</div>
                </div>
              </div>
              
              <div class="field-box-w276"
                *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && uiService.convenience09331Flag) || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && uiService.convenience54136Flag) 
                          || (dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] && uiService.mercantileRiskBewargeGorceryStoreFlag)">
                <label class="field-label-w276">Liquor Liability Coverage</label>
                <div class="toggle-group-w276">
                  <div value="Y" class="toggle-button-left-w138"
                    [ngClass]="liquorLiabilityCoverageSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onliquorLiabilityCoverageClick(true)">YES
                  </div>
                  <div value="N" class="toggle-button-right-w138"
                    [ngClass]="liquorLiabilityCoverageSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onliquorLiabilityCoverageClick(false)">NO</div>
                </div>
              </div>

              <div class="field-box-w276"
                *ngIf="(dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] || dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) && liquorLiabilityCoverageSelectedVal == 'Y'">
                <label class="field-label-w276 required-field">Liquor Liability Sales</label>
                <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1"
                  max="32" maxDigits="9" [formControlName]="contractorConstants.LiquorSales" required />

              </div>
              <div class="field-box-w276"
                *ngIf="(dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] || dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) && liquorLiabilityCoverageSelectedVal == 'Y'">
                <label class="field-label-w276">Limit</label>
                <label class="field-value-liab-w42">{{liquiorLiabilityLimitLabelValue}}</label>
              </div>
            </div>
          </div>
          <div class="liability-sec no-default-height"
            *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
            <div class="section-title">
              <p class="title-text">Optional Liability Coverages</p>
            </div>
            <div class="row-frame-h62">
              <div class="field-box-w735">
                <label class="field-label-w750 required-field">Snow Plowing liability for private driveways, parking
                  lots with maximum
                  area 7500 sqft, and/or roads and highways
                  <div class="link-form-tooltip">
                    <img src="../../../assets/img/icon-info.png">
                    <span class="link-form-tooltiptext">
                      <a class="link-form-link" href='../../../assets/docs/MA 016 (12-21) Snow Plow Products.pdf'
                        target="_blank" rel="noopener noreferrer">
                        Form MA 016
                      </a>
                    </span>
                  </div>
                </label>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="snowPlowingSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onSnowPlowingClick(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="snowPlowingSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onSnowPlowingClick(false)">No</div>
                  <!-- snowIceRemoval, privateDriveways, parkingLots, roadHighways-->
                </div>
                <div *ngIf="uiService.snowPlowingRequiredFlag" class="alert">
                  Required
                </div>
              </div>
            </div>
            <div class="row-frame-h62" *ngIf="snowPlowingSelectedVal == 'Y'">
              <div class="field-box-w276">
                <label class="field-label-w276">Number of Plows</label>
                <input class="field-input-w276" onlyNumber minlength="1" maxlength="20"
                  [formControlName]="contractorConstants.cNoOfPlows" id="liability-number-of-plows" />
              </div>
            </div>
          </div>
          <div class="liability-sec-h288" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
            <div class="section-title">
              <p class="title-text">Additional Insured Options</p>
            </div>
            <div class="row-frame-h40">
              <div class="add-ins-opt-field-box">
                <label class="field-label-w282">Blanket Ongoing Operations</label>
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">
                  <span class="link-form-tooltiptext">
                    <a class="link-form-link"
                      href='../../../assets/docs/BP 04 51 (07-13) Additional Insured - Owners Lessees.pdf'
                      target="_blank" rel="noopener noreferrer">
                      Form BP 04 51
                    </a>
                  </span>
                </div>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="blanketOngoingSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onBlanketOngoingClick(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="blanketOngoingSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onBlanketOngoingClick(false)">No</div>
                </div>
              </div>
            </div>
            <div class="row-frame-h40">
              <div class="add-ins-opt-field-box">
                <label class="field-label-w282">Blanket Ongoing/Completed Operations</label>
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">
                  <span class="link-form-tooltiptext">
                    <a class="link-form-link" href='../../../assets/docs/MA 166 (12-17) Additional Insured - Owners.pdf'
                      target="_blank" rel="noopener noreferrer">
                      Form MA 166
                    </a>
                  </span>
                </div>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="blanketCompletedSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onBlanketCompletedClick(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="blanketCompletedSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onBlanketCompletedClick(false)">No</div>
                </div>
              </div>
            </div>
            <div class="row-frame-h40">
              <div class="add-ins-opt-field-box">
                <label class="field-label-w282">Waiver of Subrogation</label>
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">
                  <span class="link-form-tooltiptext">
                    <a class="link-form-link"
                      href='../../../assets/docs/BP 04 97 (01-06) Waiver Of Transfer Of Rights Of.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Form BP 04 97
                    </a>
                  </span>
                </div>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="waiverOfSubSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onWaiverOfSubClick(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="waiverOfSubSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onWaiverOfSubClick(false)">No</div>
                </div>
              </div>
            </div>
            <div class="row-frame-h40">
              <div class="add-ins-opt-field-box">
                <label class="field-label-w282">Primary Non-contributory</label>
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">
                  <span class="link-form-tooltiptext">
                    <a class="link-form-link" href='../../../assets/docs/MA 167 (12-17) Primary and Noncontributory.pdf'
                      target="_blank" rel="noopener noreferrer">
                      Form MA 167
                    </a>
                  </span>
                </div>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="primNonContributorySelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onPrimNonContributoryClick(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="primNonContributorySelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onPrimNonContributoryClick(false)">No</div>
                </div>
              </div>
            </div>
          </div>

          <div class="liability-sec" *ngIf="uiService.natureOfBusines =='MERCANTILE_RISKS'">
            <div class="section-title">
              <p class="title-text">Liquor Liability</p>
            </div>
            <div class="row-frame-h62">
              <div class="field-box-w735">
                <div class="toggle-group-w288">
                  <div class="toggle-button-left-w144"
                    [ngClass]="liquorSelectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onLiquorClick(true)">Yes</div>
                  <div class="toggle-button-right-w144"
                    [ngClass]="liquorSelectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onLiquorClick(false)">No</div>
                </div>
              </div>
            </div>
            <div class="field-box-w276"
              *ngIf="uiService.natureOfBusines =='MERCANTILE_RISKS' && liquorSelectedVal == 'Y'">
              <label class="field-label-w276">Limit</label>
              <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1"
                max="32" maxDigits="9" [formControlName]="" required />
            </div>
            <div class="field-box-w276"
              *ngIf="uiService.natureOfBusines =='MERCANTILE_RISKS' && liquorSelectedVal == 'Y'">
              <label class="field-label-w276">Liquor Liability Sales</label>
              <input class="field-input-w276" placeholder="Amount in dollars" autocomplete="off" currencyInput min="1"
                max="32" maxDigits="9" [formControlName]="" required />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="rectangle"></div>

</div>