import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { ComponentService } from '@app/core/services/component.service';
import { DataMappingService } from "@app/core/services/data-mapping.service";
import { DataService } from "@app/core/services/data.service";
import { PrefillService } from '../../core/services/prefill.service';
import { PropertyDataMappingService } from '../../core/services/property-data-mapping.service';
import { dmgPremRentYouLimit, apartmentBldEnd, restEndorsement, BuildingLimitInsurance, BusinssPersonalPropLmt, OptionalPropertyDedu, YearBuilt, DistanceFromFireStation, DistToNearestHydrant, cRoof, cPlumbing, cHeating, cWaterHeater, cElectrical, autoSprinkleredSystem, Interest, NumberOfUnits, NumberOccupiedByOwners, NumberVacant, NumberLeasedToOthers, BuildingSquareFootage, ConstructionType } from "@app/contractor.constant";

import * as contractorConstants from '@app/contractor.constant';
import { DropdownService } from "@app/core/services/dropdown.service";
import { UiService } from "@app/core/services/ui.service";
import { BusinessAttribute } from "@app/core/enums/business-attributes.enum";
import { distinctUntilChanged,Subscription} from "rxjs";
import { environment } from "@env/environment";


@Component({
  selector: "app-property-details",
  templateUrl: "./property-details.component.html",
  styleUrls: ["./property-details.component.scss"]
})
export class PropertyDetailsComponent implements OnInit, OnDestroy{
  buldingLimitChecked = false;
  localAlarmFireChecked = false;
  localAlarmBurglarChecked = false;
  centralStationAlarmFireChecked = false;
  centralStationAlarmBurglarChecked = false;
  SmokeDetectorsBatteryChecked = false;
  SmokeDetectorsHardwiredChecked = false;

  propertyDetailsForm: FormGroup;
  RemoveMoveNextSubscription:Subscription;
  RemoveBuildingsSubscription:Subscription;
  RemoveTabsSubscription:Subscription;
  removeSqFootageDeclinationLocSubscription:Subscription;


  PropertyDetailsLocationOneForm: FormGroup;
  PropertyDetailsLocationTwoForm: FormGroup;
  PropertyDetailsLocationThreeForm: FormGroup;

  yearofConstructionCheck: boolean;
  yearofBuiltFailedFlag: boolean = false;
  sumOfUnitsValidFlag: boolean = true;
  public addressBpLocation: string;

  public basicBuildingCoverLimitFailedFlag: boolean = false;
  public basicbusinessPersonalPropertyLimitFailedFlag: boolean = false;
  public basicbusinessPersonalPropertyLimitFailedFlagOne: boolean = false;
  public basicbusinessPersonalPropertyLimitSmallContractorFailedFlag: boolean = false;
  public basicbusinessPersonalPropertyLimitNonSmallContractorFailedFlag: boolean = false;
  buildingSquareFootageMandatoryFlag

  public isReadOnly: boolean = false;

  public processedInterestOptions: any = [];

  public deductibleOptions: any = [];

  onIntrestChangeMandatoryBppLocationOne: boolean = false;
  onIntrestChangeMandatoryBppLocationTwo: boolean = false;
  onIntrestChangeMandatoryBppLocationThree: boolean = false;

  public apartmentBuildingEndorsementSelectedVal: string;

  apartmentLocationOneEndorsementSelectedVal: string;
  apartmentLocationTwoEndorsementSelectedVal: string;
  apartmentLocationThreeEndorsementSelectedVal: string;

  public restaurantEndorsementSelectedVal: string;
  l1apartmentBuildingEndorsementSelectedVal: string;
  l2apartmentBuildingEndorsementSelectedVal: string;
  l3apartmentBuildingEndorsementSelectedVal: string;

  public restaurantLocationOneEndorsementSelectedVal: string;
  public restaurantLocationTwoEndorsementSelectedVal: string;
  public restaurantLocationThreeEndorsementSelectedVal: string;

  policyStartPayroll: string;

  public processingPhotoService71899Flag: boolean = false;
  public psPhotoServiceSelectedVal: string;

  public processingBarberService7195271332Flag: boolean = false;
  public psBarberBeauticianSelectedVal: string;

  public veterinarianEndorsementSelectedVal: string;
  public e2vLinkUrl=environment.e2vLink;

  contractorConstants = contractorConstants;
  BusinessAttribute = BusinessAttribute;
  constructor(private router: Router, private fb: FormBuilder, private componentService: ComponentService, public dataService: DataService, public dataMappingService: DataMappingService, public dropDownService: DropdownService, public uiService: UiService,  public propertyDataMappingService: PropertyDataMappingService,public prefillService: PrefillService) {
    this.componentService.currentComponent = 'property';
    this.componentService.currentComponentChanged.emit('property');
  }

  initializePropertyDetailsForm() {
    this.PropertyDetailsLocationOneForm = new FormGroup({
      LocationOne: this.fb.array([
        this.InitialData()
      ]),
    });

    this.PropertyDetailsLocationTwoForm = new FormGroup({
      LocationTwo: this.fb.array([
        this.InitialData()
      ]),
    });

    this.PropertyDetailsLocationThreeForm = new FormGroup({
      LocationThree: this.fb.array([
        this.InitialData()
      ]),
    });

  }

  InitialData() {
    const selectedDmgPremRentYouLimitValue = this.dataMappingService.convertToDollarFormat(Number(this.dataService.BPBuilding.dmgPremRentYouLimit));
    return this.fb.group({
      [BuildingLimitInsurance]: this.fb.control(""),
      [BusinssPersonalPropLmt]: this.fb.control(''),
      [OptionalPropertyDedu]: this.fb.control(this.dataMappingService.convertToDollarFormat(Number(this.dataService.BPBuilding.OptionalPropertyDedu)), Validators.required),
      [YearBuilt]: this.fb.control("", [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      [ConstructionType]: this.fb.control("", Validators.required),
      [DistanceFromFireStation]: this.fb.control("", Validators.required),
      [DistToNearestHydrant]: this.fb.control("", Validators.required),
      [cRoof]: this.fb.control(""),
      [cPlumbing]: this.fb.control(""),
      [cHeating]: this.fb.control(""),
      [cWaterHeater]: this.fb.control(""),
      [cElectrical]: this.fb.control(""),
      "yearOfOldestUpdate": this.fb.control(''), // need to find and map later
      "fireAlarmALocalAlarm": this.fb.control(''),
      "CrmProtAlmALocalAlm": this.fb.control(''),
      "fireAlarmACenStnAlarm": this.fb.control(''),
      "CrmProtAlmACenStAlm": this.fb.control(''),
      "fireAlarmABatopSmkDet": this.fb.control(''),
      "fireAlarmAHrdwrSmkDet": this.fb.control(''),

      [autoSprinkleredSystem]: this.fb.control(''),
      [Interest]: this.fb.control('', Validators.required),
      [BuildingSquareFootage]: this.fb.control(""),
      [NumberOfUnits]: this.fb.control(""),
      [NumberOccupiedByOwners]: this.fb.control(""),
      [NumberVacant]: this.fb.control(""),
      [NumberLeasedToOthers]: this.fb.control(this.dataService.BPBuilding.NumberLeasedToOthers),
      [dmgPremRentYouLimit]: this.fb.control(selectedDmgPremRentYouLimitValue, Validators.required),
      [apartmentBldEnd]: this.fb.control(''),
      [restEndorsement]: this.fb.control(''),
      "restaurantEndorsementSelectedVal": this.fb.control(''),
      "apartmentBuildingEndorsementSelectedVal": this.fb.control(''),
      "buildingroofselectedVal": this.fb.control('N'),
      "buldingLimitChecked": this.fb.control(false),
      "sumOfUnitsValidFlag": this.fb.control(true),
      "buildinglimitMandatoryFlag": this.fb.control(true),
      "isBuildingRoofSelectedVal": this.fb.control("N"),
      "ppc": this.fb.control(""),
      "yearofBuiltFailedFlag": this.fb.control(false),
      "totalPayroll": this.fb.control(""),
      "psPhotoServiceSelectedVal":this.fb.control(""),
      "veterinarianEndorsementSelectedVal":this.fb.control(""),
      "numberOfVeterinarian":this.fb.control(""),
      "totalSquareFootageFlag":this.fb.control(false),
      "numberOfVeterinarianFailFlag":this.fb.control(false),
      "psBarberBeauticianSelectedVal":this.fb.control(""),
      "noOfFullTimeBarbers":this.fb.control(""),
      "noOfPartTimeBarbers":this.fb.control(""),
      "noOfFlTmBuPrEnPf":this.fb.control(""),
      "noOfPtTmBuPrEnPf":this.fb.control(""),
      "AnualGrossSales":this.fb.control(""),
      "photography":this.fb.control(""),
      "brbBeutPrsEnhPrfLb":this.fb.control(""),
      "professionalClass":this.fb.control(""),
      "builpsPhotoServiceSelectedVal":this.fb.control(""),
      "veterinarProfLiab":this.fb.control(""),
      "isLiabilityPayrollNotValid":this.fb.control(""),
      "cReconstrConstr":this.fb.control(""),
      "resAuRepMnufcProcOcup":this.fb.control(""),
      "yearofConstructionCheck":this.fb.control("")
    })
  }

  goToPage(pageName: string, next?: boolean): void {
    if (next) {
      this.dataMappingService.mapPropertyDetailsFormDatatoFinalJson(this.propertyDetailsForm);
    }
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit(): void {
    this.initializeFormDefaultValues();
    this.deductibleOptions = this.uiService.isDateAfter ? this.dropDownService.deductibleafterDec282023Options : this.dropDownService.deductibleOptions;
    this.initializePropertyDetailsForm();
    console.log(this.dataMappingService.originalClassCodeObj,"org classcode obj");
    this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
    if (this.uiService.hasDataPrefilled) {
      setTimeout(() => {
        this.prefillValuesFromMarketplace();
        this.LocationsOneBuildings.controls.forEach((element) => {
          element.get("Interest").setValue("");
        })
        if (this.dataMappingService.originalClassCodeObj.classcode == '69145') {
          this.LocationsOneBuildings.at(0).get("Interest").setValue(this.dropDownService.interestOptions[1]);
        }
        if (this.dataMappingService.originalClassCodeObj.classcode2 == '69145') {
          this.LocationsTwoBuildings.at(0).get("Interest").setValue(this.dropDownService.interestOptions[1]);
        }
        if (this.dataMappingService.originalClassCodeObj.classcode3 == '69145') {
          this.LocationsThreeBuildings.at(0).get("Interest").setValue(this.dropDownService.interestOptions[1]);
        }
      }, 150)
    }

   this.RemoveTabsSubscription =this.uiService.LocationTabs.subscribe((data: any) => {
      if (data === '1') {
        this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
      }
     else if (data === '2') {
      console.log(this.PropertyDetailsLocationTwoForm,"PropertyDetailsLocationTwoForm")
        this.dataMappingService.currentForm = this.PropertyDetailsLocationTwoForm;
        this.uiService.InteractionType = 2;
        this.uiService.SqftFlag = false;
        let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
        this.onTotalNumberOfUnitsChange(this.LocationsTwoBuildings.controls.at(0));
        if (BuildingsList.length > 1) {
          this.uiService.LocationOneFlag = false;
          this.uiService.LocationTwoFlag = true;
          this.uiService.LocationThreeFlag = false;
          this.LocationDataPreFill(BuildingsList, '2');
        }
        const l2Buildings=[]
        BuildingsList.forEach((d)=>{
          if(d.locationId==2){
            l2Buildings.push(d);
          }
        })
        if(l2Buildings.length==0){
          this.Location2BuildingLimitFieldsInit()
        }
      }
     else if (data === '3') {
        this.dataMappingService.currentForm = this.PropertyDetailsLocationThreeForm;
        this.uiService.InteractionType = 3;
        this.uiService.SqftFlag = false;
        let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
        this.onTotalNumberOfUnitsChange(this.LocationsThreeBuildings.controls.at(0));
        if (BuildingsList.length > 1) {
          this.uiService.LocationOneFlag = false;
          this.uiService.LocationTwoFlag = false;
          this.uiService.LocationThreeFlag = true;
          this.LocationDataPreFill(BuildingsList, '3');
        }
        let l3Buildings=[];
        BuildingsList.forEach((d)=>{
          if(d.locationId==3){
            l3Buildings.push(d);
          }
        })
        if(l3Buildings.length==0){
          this.Location3BuildingLimitFieldsInit()
        }
      }
    })
    
    this.initializeYearBuiltData(0, "PropertyDetailsLocationOneForm");
    this.initializeYearBuiltData(0, "PropertyDetailsLocationTwoForm");
    this.initializeYearBuiltData(0, "PropertyDetailsLocationThreeForm");

    this.yearBuiltValueCheck(this.dataService.BPBuilding.YearBuilt ? this.dataService.BPBuilding.YearBuilt : this.LocationsOneBuildings.controls.at(0).get([YearBuilt]).value, "PropertyDetailsLocationOneForm",0);
    this.yearBuiltValueCheck(this.dataService.BPBuilding.YearBuilt ? this.dataService.BPBuilding.YearBuilt : this.LocationsTwoBuildings.controls.at(0).get([YearBuilt]).value, "PropertyDetailsLocationTwoForm",0);
    this.yearBuiltValueCheck(this.dataService.BPBuilding.YearBuilt ? this.dataService.BPBuilding.YearBuilt : this.LocationsThreeBuildings.controls.at(0).get([YearBuilt]).value, "PropertyDetailsLocationThreeForm",0);
    this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsOneBuildings.at(0).get("isBuildingRoofSelectedVal").value), this.LocationsOneBuildings.at(0));
    this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsTwoBuildings.at(0).get("isBuildingRoofSelectedVal").value), this.LocationsTwoBuildings.at(0));
    this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsThreeBuildings.at(0).get("isBuildingRoofSelectedVal").value), this.LocationsThreeBuildings.at(0)); // dirty check

    this.buildingLimitCheck(this.LocationsOneBuildings.controls.at(0));
    this.buildingLimitCheck(this.LocationsTwoBuildings.controls.at(0));
    this.buildingLimitCheck(this.LocationsThreeBuildings.controls.at(0));

    this.processedInterestOptions = this.dropDownService.interestOptions;
    
   

    //check for Number of units zero & display error message after prefill service fillup
    this.onTotalNumberOfUnitsChange(this.LocationsOneBuildings.controls.at(0));
    //to disable mandatory control - square Footage for "I am a tenant"
    // this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 0, "PropertyDetailsLocationOneForm");
    // this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 0, "PropertyDetailsLocationTwoForm");
    // this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 0, "PropertyDetailsLocationThreeForm");

    this.deductibleOptions = this.uiService.isDateAfter ? this.dropDownService.deductibleafterDec282023Options : this.dropDownService.deductibleOptions;
    let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
    setTimeout(() => {
      if (BuildingsList.length > 1) {
        this.uiService.LocationOneFlag = true;
        this.uiService.LocationTwoFlag = false;
        this.uiService.LocationThreeFlag = false;
        this.LocationDataPreFill(BuildingsList, '1');
      }
      else {
        if (this.uiService.PropertyScreenFlag) {
          this.LocationDataPreFill(BuildingsList, '1');
        }
      }
    }, 200)
    if(BuildingsList.length<=1){
      if(this.dataService.BPBuilding.BPBuildingCoverage){
        if(this.dataService.BPBuildingCoverage.BuildingLimitInsurance==""){
          this.BuildingLimitFieldsInit()
        }
      }
    }
    
    // CONTRACTOR PAYROLL
    this.payrollHelpTextAdjust();
    this.apartmentEndorsementInit();
    this.restEndorsementInit();
    this._doRemoveDeclinedBuildings();
    this._doTabMove()
    this.removeDeclinedLocationOnDeclination()
    this.uiService.backupBPBuilding=this.dataService.BPBuilding;
  }


  BuildingLimitFieldsInit() {
    if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
      
      if (this.dataService.BPBuildingCoverage != null) {
        this.buldingLimitChecked = this.dataService.BPBuildingCoverage.BuildingLimitInsurance != "";
      }
      //condition - BL & BPPL Mutual Mandatory - forother NOB-starts
     // this.buildinglimitMandatoryFlag = this.dataService.BPClassifications.BusinssPersonalPropLmt == "";
      if(this.LocationsOneBuildings.controls.at(0).get("BuildingLimitInsurance").value==""){
        this.LocationsOneBuildings.controls.at(0).get("buildinglimitMandatoryFlag").setValue(false)
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(0).get("BuildingLimitInsurance"), true);
      this.updateConditionalMandatoryBPPLRequired(this.LocationsOneBuildings.controls.at(0));
      }
     
      if(this.LocationsTwoBuildings.controls.at(0).get("BuildingLimitInsurance").value==""){
        this.LocationsTwoBuildings.controls.at(0).get("buildinglimitMandatoryFlag").setValue(false)
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(0).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsTwoBuildings.controls.at(0));
      }

      
      if(this.LocationsThreeBuildings.controls.at(0).get("BuildingLimitInsurance").value==""){
        this.LocationsThreeBuildings.controls.at(0).get("buildinglimitMandatoryFlag").setValue(false)
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(0).get("BuildingLimitInsurance"), true);
      this.updateConditionalMandatoryBPPLRequired(this.LocationsThreeBuildings.controls.at(0));
      }

      
      
      
      //condition - BL & BPPL Mutual Mandatory - for NOB-ends
    }
    else {
      if (this.dataService.BPBuildingCoverage != null) {
        this.buldingLimitChecked = this.dataService.BPBuildingCoverage.BuildingLimitInsurance != "";
      }
    }
  }
  Location2BuildingLimitFieldsInit(){
    if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
      
      if (this.dataService.BPBuildingCoverage != null) {
        this.buldingLimitChecked = this.dataService.BPBuildingCoverage.BuildingLimitInsurance != "";
      }
      //condition - BL & BPPL Mutual Mandatory - forother NOB-starts
     // this.buildinglimitMandatoryFlag = this.dataService.BPClassifications.BusinssPersonalPropLmt == "";
     
      if(this.LocationsTwoBuildings.controls.at(0).get("BuildingLimitInsurance").value==""){
        this.LocationsTwoBuildings.controls.at(0).get("buildinglimitMandatoryFlag").setValue(false)
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(0).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsTwoBuildings.controls.at(0));
      }

      
      if(this.LocationsThreeBuildings.controls.at(0).get("BuildingLimitInsurance").value==""){
        this.LocationsThreeBuildings.controls.at(0).get("buildinglimitMandatoryFlag").setValue(false)
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(0).get("BuildingLimitInsurance"), true);
      this.updateConditionalMandatoryBPPLRequired(this.LocationsThreeBuildings.controls.at(0));
      }

      
      
      
      //condition - BL & BPPL Mutual Mandatory - for NOB-ends
    }
    else {
      if (this.dataService.BPBuildingCoverage != null) {
        this.buldingLimitChecked = this.dataService.BPBuildingCoverage.BuildingLimitInsurance != "";
      }
    }
  }
  Location3BuildingLimitFieldsInit(){
    if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
      
      if (this.dataService.BPBuildingCoverage != null) {
        this.buldingLimitChecked = this.dataService.BPBuildingCoverage.BuildingLimitInsurance != "";
      }
      //condition - BL & BPPL Mutual Mandatory - forother NOB-starts
     // this.buildinglimitMandatoryFlag = this.dataService.BPClassifications.BusinssPersonalPropLmt == "";
     
  

      
      if(this.LocationsThreeBuildings.controls.at(0).get("BuildingLimitInsurance").value==""){
        this.LocationsThreeBuildings.controls.at(0).get("buildinglimitMandatoryFlag").setValue(false)
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(0).get("BuildingLimitInsurance"), true);
      this.updateConditionalMandatoryBPPLRequired(this.LocationsThreeBuildings.controls.at(0));
      }

      
      
      
      //condition - BL & BPPL Mutual Mandatory - for NOB-ends
    }
    else {
      if (this.dataService.BPBuildingCoverage != null) {
        this.buldingLimitChecked = this.dataService.BPBuildingCoverage.BuildingLimitInsurance != "";
      }
    }
  }

  initializeFormDefaultValues() {
    //Veterinarian Endorsement Flag for class code 64181
    this.uiService.classcodeOffice64181Flag = (this.dataMappingService.originalClassCodeObj.classcode == '64181' || this.dataMappingService.originalClassCodeObj.classcode2 == '64181' || this.dataMappingService.originalClassCodeObj.classcode3 == '64181');

    //Flag for processing service class code  71952 or 71332
    this.uiService.processingProcessService7195271332Flag = (this.dataMappingService.originalClassCodeObj.classcode == '71952' || this.dataMappingService.originalClassCodeObj.classcode2 == '71952' || this.dataMappingService.originalClassCodeObj.classcode3 == '71952' || this.dataMappingService.originalClassCodeObj.classcode == '71332' || this.dataMappingService.originalClassCodeObj.classcode2 == '71332' || this.dataMappingService.originalClassCodeObj.classcode3 == '71332');
    this.uiService.processingBarberService71332Flag = (this.dataMappingService.originalClassCodeObj.classcode == '71332' || this.dataMappingService.originalClassCodeObj.classcode2 == '71332' || this.dataMappingService.originalClassCodeObj.classcode3 == '71332');
    this.uiService.processingBeauticianService71952Flag = (this.dataMappingService.originalClassCodeObj.classcode == '71952' || this.dataMappingService.originalClassCodeObj.classcode2 == '71952' || this.dataMappingService.originalClassCodeObj.classcode3 == '71952');

    //Flag for processing service class code  71865
    this.uiService.processingFuneralService71865Flag = (this.dataMappingService.originalClassCodeObj.classcode == '71865' || this.dataMappingService.originalClassCodeObj.classcode2 == '71865' || this.dataMappingService.originalClassCodeObj.classcode3 == '71865');

    //Flag for processing service class code  71899
    this.uiService.processingPhotoService71899Flag = (this.dataMappingService.originalClassCodeObj.classcode == '71899' || this.dataMappingService.originalClassCodeObj.classcode2 == '71899' || this.dataMappingService.originalClassCodeObj.classcode3 == '71899');

    //Flag for Convenience class code  09331
    this.uiService.convenience09331Flag = (this.dataMappingService.originalClassCodeObj.classcode == '09331' || this.dataMappingService.originalClassCodeObj.classcode2 == '09331' || this.dataMappingService.originalClassCodeObj.classcode3 == '09331');
    this.dataMappingService.convenience09331Flag = (this.dataMappingService.originalClassCodeObj.classcode == '09331' || this.dataMappingService.originalClassCodeObj.classcode2 == '09331' || this.dataMappingService.originalClassCodeObj.classcode3 == '09331');
    // Flag for MERCANTILE Liquor Liability Coverage on specific class codes.
    let bewareClassCodes: number[] = [59215, 54136, 54321, 54341, 54221, 54241];
    if (bewareClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode)) || bewareClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode2)) || bewareClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode3))) {
      this.uiService.mercantileRiskBewargeGorceryStoreFlag = true;
    }
    this.photographyCheck()
  }
  photographyCheck(){
    if(this.dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS])
    {
      this.dataService.quote_policy.BusinessOwners.BPBuilding.forEach((d)=>{
        if(d.locationId=="1"){
          if(this.dataMappingService.originalClassCodeObj.classcode!="71899"){
            d.BPClassifications.photography=""
          }
          if(this.dataMappingService.originalClassCodeObj.classcode!="71332"){
            d.BPClassifications.brbBeutPrsEnhPrfLb=""
          }
        }else if(d.locationId=="2"){
          if(this.dataMappingService.originalClassCodeObj.classcode2!="71899"){
            d.BPClassifications.photography=""
          }
          if(this.dataMappingService.originalClassCodeObj.classcode2!="71332"){
            d.BPClassifications.brbBeutPrsEnhPrfLb=""
          }
        }else if(d.locationId=="3"){
          if(this.dataMappingService.originalClassCodeObj.classcode3!="71899"){
            d.BPClassifications.photography=""
          }
          if(this.dataMappingService.originalClassCodeObj.classcode3!="71332"){
            d.BPClassifications.brbBeutPrsEnhPrfLb=""
          }
        }
      })

    }
  
  }

  apartmentEndorsementInit() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]) {
      let Locations: any = this.dataService.businessOwners.BPLocations;
      if (Locations.length == 1) {
        this.apartmentLocationOneEndorsementSelectedVal = Locations[0].BPInlineOBJ.apartmentBldEnd;
      }
      if (Locations.length == 2) {
        this.apartmentLocationOneEndorsementSelectedVal = Locations[0].BPInlineOBJ.apartmentBldEnd;
        this.apartmentLocationTwoEndorsementSelectedVal = Locations[1].BPInlineOBJ.apartmentBldEnd;
      }
      if (Locations.length == 3) {
        this.apartmentLocationOneEndorsementSelectedVal = Locations[0].BPInlineOBJ.apartmentBldEnd;
        this.apartmentLocationTwoEndorsementSelectedVal = Locations[1].BPInlineOBJ.apartmentBldEnd;
        this.apartmentLocationThreeEndorsementSelectedVal = Locations[2].BPInlineOBJ.apartmentBldEnd;
      }
    }
  }

  restEndorsementInit() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]) {
      let Locations: any = this.dataService.businessOwners.BPLocations;
      if (Locations.length == 1) {
        this.restaurantLocationOneEndorsementSelectedVal = Locations[0].BPInlineOBJ.restEndorsement;
      }
      if (Locations.length == 2) {
        this.restaurantLocationOneEndorsementSelectedVal = Locations[0].BPInlineOBJ.restEndorsement;
        this.restaurantLocationTwoEndorsementSelectedVal = Locations[1].BPInlineOBJ.restEndorsement;
      }
      if (Locations.length == 3) {
        this.restaurantLocationOneEndorsementSelectedVal = Locations[0].BPInlineOBJ.restEndorsement;
        this.restaurantLocationTwoEndorsementSelectedVal = Locations[1].BPInlineOBJ.restEndorsement;
        this.restaurantLocationThreeEndorsementSelectedVal = Locations[2].BPInlineOBJ.restEndorsement;
      }
    }
  }
  liabilityPayrollValidadation(building) {
    if (this.uiService.isDateAfter && building.get("totalPayroll").value) {
      if (building.get("totalPayroll").value < 40000) {
        building.get("isLiabilityPayrollNotValid").setValue(true);
        building.get("totalPayroll").setErrors({ 'totalPayroll': true });
      } else {
        building.get("isLiabilityPayrollNotValid").setValue(false);
        building.get("totalPayroll").setErrors(null);
      }
    } else if (!this.uiService.isDateAfter && building.get("totalPayroll").value) {
      if (building.get("totalPayroll").value < 35700) {
        building.get("isLiabilityPayrollNotValid").setValue(true);
        building.get("totalPayroll").setErrors({ 'totalPayroll': true });
      } else {
        building.get("isLiabilityPayrollNotValid").setValue(false);
        building.get("totalPayroll").setErrors(null);
      }
    } else {
      building.get("isLiabilityPayrollNotValid").setValue(false);
      building.get("totalPayroll").setErrors(null);
    }
  }

  payrollHelpTextAdjust() {
    if (this.uiService.isDateAfter) {
      this.policyStartPayroll = "$40,000";
    } else {
      this.policyStartPayroll = "$35,700"
    }
  }

  LocationDataPreFill(BuildingsList, Location) {
    let Buildings = [];
    if (Location === '1') {
      BuildingsList.forEach((element, index) => {
        if (element.locationId == '1') {
          Buildings.push(element);
        }
      });
      console.log(Buildings,"Buildings");
      console.log(BuildingsList,Location)
   
      if (Buildings.length > 1) {
        let L1 = this.PropertyDetailsLocationOneForm.get("LocationOne") as FormArray;
        L1.clear();
        Buildings.forEach((ele, index) => {
          this.LocationsOneBuildings.push(this.InitialData());
          this.initializeYearBuiltData(index,"PropertyDetailsLocationOneForm");
          this.buildingLimitCheck(this.LocationsOneBuildings.controls.at(index));
        })

        Buildings.forEach((ele, index) => {
          this.BuildingValuesSet(this.LocationsOneBuildings, index, ele);
         
        })
      }
      else {
        this.initializeYearBuiltData(0,"PropertyDetailsLocationOneForm");
        this.BuildingValuesSet(this.LocationsOneBuildings, 0, Buildings[0]);
        
      }
    }
    else if (Location === '2') {
     
      BuildingsList.forEach((element, index) => {
        if (element.locationId == '2') {
          Buildings.push(element);
        }
      });
   
     
      if(Buildings.length > 1){
        let L2 =  this.PropertyDetailsLocationTwoForm.get("LocationTwo") as FormArray;
        L2.clear();
        Buildings.forEach((ele, index) => {
          this.LocationsTwoBuildings.push(this.InitialData());
          this.initializeYearBuiltData(index,"PropertyDetailsLocationTwoForm");
          this.buildingLimitCheck(this.LocationsTwoBuildings.controls.at(index));
        })
        Buildings.forEach((ele, index) => {
          this.BuildingValuesSet(this.LocationsTwoBuildings, index, ele);
        })
      }
      else {
        this.initializeYearBuiltData(0,"PropertyDetailsLocationTwoForm");
        this.BuildingValuesSet(this.LocationsTwoBuildings, 0, Buildings[0]);
       
      }
    }
    else if (Location === '3') {
      BuildingsList.forEach((element, index) => {
        if (element.locationId == '3') {
          Buildings.push(element);
        }
      });
   
      if (Buildings.length > 1) {
        let L3 = this.PropertyDetailsLocationThreeForm.get("LocationThree") as FormArray;
        L3.clear();
        Buildings.forEach((ele, index) => {
          this.LocationsThreeBuildings.push(this.InitialData());
          this.initializeYearBuiltData(index,"PropertyDetailsLocationThreeForm");
          this.buildingLimitCheck(this.LocationsThreeBuildings.controls.at(index));

        })
        Buildings.forEach((ele, index) => {
          this.BuildingValuesSet(this.LocationsThreeBuildings, index, ele);
        })
      }
      else {
        this.initializeYearBuiltData(0,"PropertyDetailsLocationThreeForm");
        this.BuildingValuesSet(this.LocationsThreeBuildings, 0, Buildings[0]);
      
      }
    }
  }
  BuildingValuesSet(FormGroup, i, ele) {
    if(ele != undefined){
      FormGroup.at(i).get("BuildingLimitInsurance").setValue(ele.BPBuildingCoverage != undefined ? ele.BPBuildingCoverage.BuildingLimitInsurance : "");
      FormGroup.at(i).get("BusinssPersonalPropLmt").setValue(ele.BPClassifications.BusinssPersonalPropLmt);
      FormGroup.at(i).get("Interest").setValue(ele.selectedInterestDropdownValue);
      FormGroup.at(i).get("OptionalPropertyDedu").setValue(this.dataMappingService.convertToDollarFormat(Number(ele.OptionalPropertyDedu)));
      FormGroup.at(i).get("YearBuilt").setValue(ele.YearBuilt);
      FormGroup.at(i).get("ConstructionType").setValue(ele.ConstructionType);
      FormGroup.at(i).get("DistanceFromFireStation").setValue(ele.DistanceFromFireStation);
      FormGroup.at(i).get("DistToNearestHydrant").setValue(ele.DistToNearestHydrant);
      FormGroup.at(i).get("cRoof").setValue(ele.cRoof);
      FormGroup.at(i).get("ppc").setValue(ele.PublicProtFireClas);
      FormGroup.at(i).get("cPlumbing").setValue(ele.cPlumbing);
      FormGroup.at(i).get("cHeating").setValue(ele.cHeating);
      FormGroup.at(i).get("cWaterHeater").setValue(ele.cWaterHeater);
      FormGroup.at(i).get("cElectrical").setValue(ele.cElectrical);
      FormGroup.at(i).get("yearOfOldestUpdate").setValue(ele.yearOfOldestUpdate);
      FormGroup.at(i).get("fireAlarmALocalAlarm").setValue(this.dataMappingService.YNToBool(ele.fireAlarmALocalAlarm));
      FormGroup.at(i).get("CrmProtAlmALocalAlm").setValue(this.dataMappingService.YNToBool(ele.CrmProtAlmALocalAlm));
      FormGroup.at(i).get("fireAlarmACenStnAlarm").setValue(this.dataMappingService.YNToBool(ele.fireAlarmACenStnAlarm));
      FormGroup.at(i).get("CrmProtAlmACenStAlm").setValue(this.dataMappingService.YNToBool(ele.CrmProtAlmACenStAlm));
      FormGroup.at(i).get("fireAlarmABatopSmkDet").setValue(this.dataMappingService.YNToBool(ele.fireAlarmABatopSmkDet));
      FormGroup.at(i).get("fireAlarmAHrdwrSmkDet").setValue(this.dataMappingService.YNToBool(ele.fireAlarmAHrdwrSmkDet));
      FormGroup.at(i).get("autoSprinkleredSystem").setValue(ele.autoSprinkleredSystem);
      FormGroup.at(i).get("BuildingSquareFootage").setValue(ele.BuildingSquareFootage);
      FormGroup.at(i).get("NumberOfUnits").setValue(ele.NumberOfUnits);
      FormGroup.at(i).get("NumberOccupiedByOwners").setValue(ele.NumberOccupiedByOwners);
      FormGroup.at(i).get("NumberVacant").setValue(ele.NumberVacant);
      FormGroup.at(i).get("NumberLeasedToOthers").setValue(ele.NumberLeasedToOthers);
      FormGroup.at(i).get("dmgPremRentYouLimit").setValue(this.dataMappingService.convertToDollarFormat(Number(ele.dmgPremRentYouLimit)));
      FormGroup.at(i).get("apartmentBuildingEndorsementSelectedVal").setValue(this.dataMappingService.YNToBool(ele.apartmentBldEnd));
      FormGroup.at(i).get("restaurantEndorsementSelectedVal").setValue(this.dataMappingService.YNToBool(ele.restEndorsement));
      FormGroup.at(i).get("buildingroofselectedVal").setValue(this.dataMappingService.boolToYN(ele.BPClassifications.isBuildingRoofValueSelected));
      FormGroup.at(i).get("yearofBuiltFailedFlag").setValue(ele.BPClassifications.yearofBuiltFailedFlag),
      FormGroup.at(i).get("totalPayroll").setValue(ele.BPClassifications.totalPayroll),
      FormGroup.at(i).get("psPhotoServiceSelectedVal").setValue(ele.BPClassifications.photography),
      FormGroup.at(i).get("veterinarianEndorsementSelectedVal").setValue(ele.BPClassifications.veterinarProfLiab),
      FormGroup.at(i).get("numberOfVeterinarian").setValue(ele.BPClassifications.numberOfVeterinarian),
      FormGroup.at(i).get("totalSquareFootageFlag").setValue(ele.BPClassifications.totalSquareFootageFlag),
      FormGroup.at(i).get("numberOfVeterinarianFailFlag").setValue(ele.BPClassifications.numberOfVeterinarianFailFlag),
      FormGroup.at(i).get("psBarberBeauticianSelectedVal").setValue(ele.BPClassifications.brbBeutPrsEnhPrfLb),
      FormGroup.at(i).get("noOfFullTimeBarbers").setValue(this.uiService.processingProcessService7195271332Flag?ele.BPClassifications.noOfFullTimeBarbers:""),
      FormGroup.at(i).get("noOfPartTimeBarbers").setValue(this.uiService.processingProcessService7195271332Flag?ele.BPClassifications.noOfPartTimeBarbers:""),
      FormGroup.at(i).get("noOfFlTmBuPrEnPf").setValue(ele.BPClassifications.noOfFlTmBuPrEnPf),
      FormGroup.at(i).get("noOfPtTmBuPrEnPf").setValue(ele.BPClassifications.noOfPtTmBuPrEnPf),
      FormGroup.at(i).get("AnualGrossSales").setValue(ele.BPClassifications.AnualGrossSales),
      FormGroup.at(i).get("photography").setValue(this.uiService.processingPhotoService71899Flag?ele.BPClassifications.photography:""),
      FormGroup.at(i).get("brbBeutPrsEnhPrfLb").setValue(this.uiService.processingProcessService7195271332Flag?ele.BPClassifications.brbBeutPrsEnhPrfLb:""),
      FormGroup.at(i).get("professionalClass").setValue(ele.BPClassifications.professionalClass),
      FormGroup.at(i).get("builpsPhotoServiceSelectedVal").setValue(ele.BPClassifications.builpsPhotoServiceSelectedVal),
      FormGroup.at(i).get("veterinarProfLiab").setValue(ele.BPClassifications.veterinarProfLiab),
      FormGroup.at(i).get("buildinglimitMandatoryFlag").setValue( (ele.BPBuildingCoverage != undefined && ele.BPBuildingCoverage.BuildingLimitInsurance == "" && ele.BPClassifications.BusinssPersonalPropLmt=="") || (ele.BPBuildingCoverage != undefined && ele.BPBuildingCoverage.BuildingLimitInsurance != "" && ele.BPClassifications.BusinssPersonalPropLmt=="")),
      FormGroup.at(i).get("cReconstrConstr").setValue(ele.cReconstrConstr != undefined ? ele.cReconstrConstr : ""),
      FormGroup.at(i).get("resAuRepMnufcProcOcup").setValue(ele.resAuRepMnufcProcOcup != undefined ? ele.resAuRepMnufcProcOcup : "")
    }
   
  }

  initializeYearBuiltData(index, FormType) {
    if (FormType === "PropertyDetailsLocationOneForm") {
      if (true) {
        this.LocationsOneBuildings.controls.at(index).get(YearBuilt).valueChanges.subscribe(value => {
          this.yearBuiltValueCheck(value, FormType,index);
        });
      }
    }

    if (FormType === "PropertyDetailsLocationTwoForm") {
      if (true) {
        this.LocationsTwoBuildings.controls.at(index).get(YearBuilt).valueChanges.subscribe(value => {
          this.yearBuiltValueCheck(value, FormType,index);
        });
      }
    }

    if (FormType === "PropertyDetailsLocationThreeForm") {
      if (true) {
        this.LocationsThreeBuildings.controls.at(index).get(YearBuilt).valueChanges.subscribe(value => {
          this.yearBuiltValueCheck(value, FormType,index);
        });
      }
    }
  }

 

  yearBuiltValueCheck(value, FormType,index) {
    this.uiService.yearofConstructionCheck = false;
    const currentYear = new Date().getFullYear();
    const countYear = currentYear - value;
    if (value != null && value.toString().length >= 4 && countYear >= 30) {
      if(FormType === "PropertyDetailsLocationOneForm"){
        this.uiService.LocationOneyearofConstructionCheck = true;
        this.uiService.LocationOneYearofConstruction[index].buildingCheck=true;
      }
      if(FormType === "PropertyDetailsLocationTwoForm"){
        this.uiService.LocationTwoyearofConstructionCheck = true;
        this.uiService.LocationTwoYearofConstruction[index].buildingCheck=true;
      }
      if(FormType === "PropertyDetailsLocationThreeForm"){
        this.uiService.LocationThreeyearofConstructionCheck = true;
        this.uiService.LocationThreeYearofConstruction[index].buildingCheck=true;
      }
      this.uiService.yearofConstructionCheck = true;
      this.buildingroofselectedVal = "N";
    } else {
      this.uiService.yearofConstructionCheck = false;
      if(FormType === "PropertyDetailsLocationOneForm"){
        this.uiService.LocationOneyearofConstructionCheck = false;
        this.uiService.LocationOneYearofConstruction[index].buildingCheck=false;
        this.LocationsOneBuildings.controls[index].get("yearOfOldestUpdate").setValue("");
        this.LocationsOneBuildings.controls[index].get("buildingroofselectedVal").setValue('N')
      }
      if(FormType === "PropertyDetailsLocationTwoForm"){
        this.uiService.LocationTwoyearofConstructionCheck = false;
        this.uiService.LocationTwoYearofConstruction[index].buildingCheck=false;
        this.LocationsTwoBuildings.controls[index].get("yearOfOldestUpdate").setValue("");
        this.LocationsTwoBuildings.controls[index].get("buildingroofselectedVal").setValue('N')
      }
      if(FormType === "PropertyDetailsLocationThreeForm"){
        this.uiService.LocationThreeyearofConstructionCheck = false;
        this.uiService.LocationThreeYearofConstruction[index].buildingCheck=false;
        this.LocationsThreeBuildings.controls[index].get("yearOfOldestUpdate").setValue("");
        this.LocationsThreeBuildings.controls[index].get("buildingroofselectedVal").setValue('N')
      }
      this.buildingroofselectedVal = "N";
      this.updateConditionalMandatoryyearOfOldestUpdateRequired(false,FormType,index);

  
    }
  }

  prefillValuesFromMarketplace() {
    console.log("prefill market place");
    console.log(this.dataService.marketplace_prefill_response,"this.dataService.marketplace_prefill_response")
    if (this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects.length > 0) {
      this.setFormArrayPropertyValue('PropertyDetailsLocationOneForm', 0, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue('PropertyDetailsLocationOneForm', 0, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue('PropertyDetailsLocationOneForm', 0, DistanceFromFireStation, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.distanceToFireStation);
      this.setFormArrayPropertyValue('PropertyDetailsLocationOneForm', 0, DistToNearestHydrant, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.distanceToFireHydrant);
      this.setFormArrayPropertyValue('PropertyDetailsLocationOneForm', 0, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.numUnits);
      this.dataMappingService.ppc = this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.risks.ppcCode;
      this.LocationsOneBuildings.at(0).get("ppc").setValue(this.dataMappingService.ppc);
    }

    if (this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects.length > 1) {
      this.setFormArrayPropertyValue('PropertyDetailsLocationTwoForm', 0, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue('PropertyDetailsLocationTwoForm', 0, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue('PropertyDetailsLocationTwoForm', 0, DistanceFromFireStation, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.distanceToFireStation);
      this.setFormArrayPropertyValue('PropertyDetailsLocationTwoForm', 0, DistToNearestHydrant, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.distanceToFireHydrant);
      this.setFormArrayPropertyValue('PropertyDetailsLocationTwoForm', 0, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.numUnits);
      this.dataMappingService.ppc1 = this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.risks.ppcCode;
      this.LocationsTwoBuildings.at(0).get("ppc").setValue(this.dataMappingService.ppc1);
    }

    if (this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects.length > 2) {
      this.setFormArrayPropertyValue('PropertyDetailsLocationThreeForm', 0, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue('PropertyDetailsLocationThreeForm', 0, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue('PropertyDetailsLocationThreeForm', 0, DistanceFromFireStation, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.distanceToFireStation);
      this.setFormArrayPropertyValue('PropertyDetailsLocationThreeForm', 0, DistToNearestHydrant, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.distanceToFireHydrant);
      this.setFormArrayPropertyValue('PropertyDetailsLocationThreeForm', 0, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.numUnits);
      this.dataMappingService.ppc2 = this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.risks.ppcCode;
      this.LocationsThreeBuildings.at(0).get("ppc").setValue(this.dataMappingService.ppc2);
    }
    this.dataMappingService.coastalZone = this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.coastal_underwriting_guidelines;
    this.uiService.hasDataPrefilled = false;
  }

  setFormControlValue(inputFieldName, inputFieldValue) {
    this.propertyDetailsForm.get(inputFieldName).setValue(inputFieldValue);
  }

  setFormArrayPropertyValue(FormType, index: number, inputFieldName, inputFieldValue) {
    if (FormType === "PropertyDetailsLocationOneForm") {
      if (this.LocationsOneBuildings.at(index).get(inputFieldName)) {
        this.LocationsOneBuildings.at(index).get(inputFieldName).setValue(inputFieldValue);
      }
    }
    if (FormType === "PropertyDetailsLocationTwoForm") {
      if (this.LocationsTwoBuildings.at(index).get(inputFieldName)) {
        this.LocationsTwoBuildings.at(index).get(inputFieldName).setValue(inputFieldValue)
      }
    }
    if (FormType === "PropertyDetailsLocationThreeForm") {
      if (this.LocationsThreeBuildings.at(index).get(inputFieldName)) {
        this.LocationsThreeBuildings.at(index).get(inputFieldName).setValue(inputFieldValue)
      }
    }
  }

  getYearsOld() {
    let year = this.propertyDetailsForm.controls[this.contractorConstants.YearBuilt].value;
  }

  public check: boolean;
  public buildingroofCheck: boolean;
  public deductibleOption: string;
  public constructionOption: string;
  public interestOption: string;
  public buildingroofselectedVal: string;
  public sprinkleredSelectedVal: string;
  public buildinglimitMandatoryFlag: boolean = true;
  public buildingSquareFootageMandatoryFlagLocationOne: boolean = true;
  public buildingSquareFootageMandatoryFlagLocationTwo: boolean = true;
  public buildingSquareFootageMandatoryFlagLocationThree: boolean = true;

  buildingLimitCheck(inputFieldName) {
    inputFieldName.get("BuildingLimitInsurance").valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      if (value && value > 0) {
        inputFieldName.get("buldingLimitChecked").setValue(true);
        this.updateConditionalMandatoryRequired(inputFieldName.get("buldingLimitChecked").value, inputFieldName);
      } else {
        inputFieldName.get("buldingLimitChecked").setValue(false);
        // //BPP Limited Should be Mandatorday if Builiding Limit is not entered
        if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
          this.updateConditionalMandatoryBPPLRequired(inputFieldName);
          inputFieldName.get("buildinglimitMandatoryFlag").setValue(false);
          this.buildinglimitMandatoryFlag = false;
        }
        this.updateConditionalMandatoryRequired(inputFieldName.get("buldingLimitChecked").value, inputFieldName);
      }
    });

  }

  _doShowSqrFtValidation(inputFieldName){
    if(inputFieldName.get("BuildingLimitInsurance").value !="" && inputFieldName.get("BuildingSquareFootage").value ==""){
      this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get("BuildingSquareFootage"), true);
    }
  }

  get LocationsOneBuildings(): any {
    return this.PropertyDetailsLocationOneForm?.get('LocationOne') as FormArray;
  }
  get LocationsTwoBuildings(): any {
    return this.PropertyDetailsLocationTwoForm.get('LocationTwo') as FormArray;
  }
  get LocationsThreeBuildings(): any {
    return this.PropertyDetailsLocationThreeForm.get('LocationThree') as FormArray;
  }

  AddLocationOneBuilding(index) {
    this.LocationsOneBuildings.push(this.InitialData());
    if (this.LocationsOneBuildings.controls.length == 2) {
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 1, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 1, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 1, DistanceFromFireStation, this.LocationsOneBuildings.at(0).get("DistanceFromFireStation").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 1, DistToNearestHydrant, this.LocationsOneBuildings.at(0).get("DistToNearestHydrant").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 1, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.numUnits);
      this.initializeYearBuiltData(1, "PropertyDetailsLocationOneForm");
      this.yearBuiltValueCheck(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt ? this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt : this.LocationsOneBuildings.controls.at(1).get([YearBuilt]).value, "PropertyDetailsLocationOneForm",1);
      this.LocationsOneBuildings.at(1).get("ppc").setValue(this.dataMappingService.ppc);
      this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 1, "PropertyDetailsLocationOneForm");
      this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsOneBuildings.at(1).get("isBuildingRoofSelectedVal").value), this.LocationsOneBuildings.at(1));

      this.onTotalNumberOfUnitsChange(this.LocationsOneBuildings.controls.at(1));

      if (this.dataMappingService.originalClassCodeObj.classcode == '69145') {
        this.LocationsOneBuildings.at(1).get("Interest").setValue(this.dropDownService.interestOptions[1]);
      }

      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){
        this.LocationsOneBuildings.controls.at(1).get("buildinglimitMandatoryFlag").setValue(false);
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(1).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsOneBuildings.controls.at(1));
      }
      this.buildingLimitCheck(this.LocationsOneBuildings.controls.at(1));
    }
    if (this.LocationsOneBuildings.controls.length == 3) {
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 2, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 2, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 2, DistanceFromFireStation, this.LocationsOneBuildings.at(0).get("DistanceFromFireStation").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 2, DistToNearestHydrant, this.LocationsOneBuildings.at(0).get("DistToNearestHydrant").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationOneForm", 2, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.numUnits);
      this.LocationsOneBuildings.at(2).get("ppc").setValue(this.dataMappingService.ppc);
      this.initializeYearBuiltData(2, "PropertyDetailsLocationOneForm");
      this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 2, "PropertyDetailsLocationOneForm");
      this.yearBuiltValueCheck(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt ?this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.building.yearBuilt : this.LocationsOneBuildings.controls.at(2).get([YearBuilt]).value, "PropertyDetailsLocationOneForm",2);
      this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsOneBuildings.at(2).get("isBuildingRoofSelectedVal").value), this.LocationsOneBuildings.at(2));

      this.onTotalNumberOfUnitsChange(this.LocationsOneBuildings.controls.at(2));
      if (this.dataMappingService.originalClassCodeObj.classcode == '69145') {
        this.LocationsOneBuildings.at(2).get("Interest").setValue(this.dropDownService.interestOptions[1]);
      }
      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){
        this.LocationsOneBuildings.controls.at(2).get("buildinglimitMandatoryFlag").setValue(false);
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(2).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsOneBuildings.controls.at(2));
      }
      this.buildingLimitCheck(this.LocationsOneBuildings.controls.at(2));
    }
  }

  AddLocationTwoBuilding(index) {
    this.LocationsTwoBuildings.push(this.InitialData());
    if (this.LocationsTwoBuildings.controls.length == 2) {
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 1, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 1, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 1, DistanceFromFireStation, this.LocationsTwoBuildings.at(0).get("DistanceFromFireStation").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 1, DistToNearestHydrant, this.LocationsTwoBuildings.at(0).get("DistToNearestHydrant").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 1, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.numUnits);
      this.initializeYearBuiltData(1, "PropertyDetailsLocationTwoForm");
      this.yearBuiltValueCheck(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt ? this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt : this.LocationsTwoBuildings.controls.at(1).get([YearBuilt]).value, "PropertyDetailsLocationTwoForm",1);
      this.LocationsTwoBuildings.at(1).get("ppc").setValue(this.dataMappingService.ppc1);
      this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 1, "PropertyDetailsLocationTwoForm");
      this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsTwoBuildings.at(1).get("isBuildingRoofSelectedVal").value), this.LocationsTwoBuildings.at(1));

      this.onTotalNumberOfUnitsChange(this.LocationsTwoBuildings.controls.at(1));
      if (this.dataMappingService.originalClassCodeObj.classcode2 == '69145') {
        this.LocationsTwoBuildings.at(1).get("Interest").setValue(this.dropDownService.interestOptions[1]);
      }

      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){
        this.LocationsTwoBuildings.controls.at(1).get("buildinglimitMandatoryFlag").setValue(false);
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(1).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsTwoBuildings.controls.at(1));
      }
      this.buildingLimitCheck(this.LocationsTwoBuildings.controls.at(1));
    }
    if (this.LocationsTwoBuildings.controls.length == 3) {
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 2, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 2, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 2, DistanceFromFireStation, this.LocationsTwoBuildings.at(0).get("DistanceFromFireStation").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 2, DistToNearestHydrant, this.LocationsTwoBuildings.at(0).get("DistToNearestHydrant").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationTwoForm", 2, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.numUnits);
      this.LocationsTwoBuildings.at(2).get("ppc").setValue(this.dataMappingService.ppc1);
      this.initializeYearBuiltData(2, "PropertyDetailsLocationTwoForm");
      this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 2, "PropertyDetailsLocationTwoForm");
      this.yearBuiltValueCheck(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt ? this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[1].insurableObject.building.yearBuilt : this.LocationsTwoBuildings.controls.at(2).get([YearBuilt]).value, "PropertyDetailsLocationTwoForm",2);
      this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsTwoBuildings.at(2).get("isBuildingRoofSelectedVal").value), this.LocationsTwoBuildings.at(2));

      this.onTotalNumberOfUnitsChange(this.LocationsTwoBuildings.controls.at(2));
      if (this.dataMappingService.originalClassCodeObj.classcode2 == '69145') {
        this.LocationsTwoBuildings.at(2).get("Interest").setValue(this.dropDownService.interestOptions[1]);
      }
      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){
        this.LocationsTwoBuildings.controls.at(2).get("buildinglimitMandatoryFlag").setValue(false);
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(2).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsTwoBuildings.controls.at(2));
      }
      this.buildingLimitCheck(this.LocationsTwoBuildings.controls.at(2));
    }
  }

  AddLocationThreeBuilding(index) {
    this.LocationsThreeBuildings.push(this.InitialData());
    if (this.LocationsThreeBuildings.controls.length == 2) {
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 1, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 1, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 1, DistanceFromFireStation, this.LocationsThreeBuildings.at(0).get("DistanceFromFireStation").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 1, DistToNearestHydrant, this.LocationsThreeBuildings.at(0).get("DistToNearestHydrant").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 1, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.numUnits);
      this.initializeYearBuiltData(1, "PropertyDetailsLocationThreeForm");
      this.yearBuiltValueCheck(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt ? this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt : this.LocationsThreeBuildings.controls.at(1).get([YearBuilt]).value, "PropertyDetailsLocationThreeForm",1);
      this.LocationsThreeBuildings.at(1).get("ppc").setValue(this.dataMappingService.ppc2);
      this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 1, "PropertyDetailsLocationThreeForm");
      this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsThreeBuildings.at(1).get("isBuildingRoofSelectedVal").value), this.LocationsThreeBuildings.at(1));

      this.onTotalNumberOfUnitsChange(this.LocationsThreeBuildings.controls.at(1));
      if (this.dataMappingService.originalClassCodeObj.classcode3 == '69145') {
        this.LocationsThreeBuildings.at(1).get("Interest").setValue(this.dropDownService.interestOptions[1]);
      }
      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){
        this.LocationsThreeBuildings.controls.at(1).get("buildinglimitMandatoryFlag").setValue(false);
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(1).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsThreeBuildings.controls.at(1));
      }
      this.buildingLimitCheck(this.LocationsThreeBuildings.controls.at(1));
    }
    if (this.LocationsThreeBuildings.controls.length == 3) {
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 2, YearBuilt, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 2, ConstructionType, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.constructionType);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 2, DistanceFromFireStation, this.LocationsThreeBuildings.at(0).get("DistanceFromFireStation").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 2, DistToNearestHydrant, this.LocationsThreeBuildings.at(0).get("DistToNearestHydrant").value);
      this.setFormArrayPropertyValue("PropertyDetailsLocationThreeForm", 2, NumberOfUnits, this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.numUnits);
      this.LocationsThreeBuildings.at(2).get("ppc").setValue(this.dataMappingService.ppc2);
      this.initializeYearBuiltData(2, "PropertyDetailsLocationThreeForm");
      this.onInterestChange(this.dataMappingService.selectedInterestDropdownValue, 2, "PropertyDetailsLocationThreeForm");
      this.yearBuiltValueCheck(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt? this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[2].insurableObject.building.yearBuilt : this.LocationsThreeBuildings.controls.at(2).get([YearBuilt]).value, "PropertyDetailsLocationThreeForm",2);
      this.onbuildingroofclick(this.dataMappingService.YNToBool(this.LocationsThreeBuildings.at(2).get("isBuildingRoofSelectedVal").value), this.LocationsThreeBuildings.at(2));

      this.onTotalNumberOfUnitsChange(this.LocationsThreeBuildings.controls.at(2));
      if (this.dataMappingService.originalClassCodeObj.classcode3 == '69145') {
        this.LocationsThreeBuildings.at(2).get("Interest").setValue(this.dropDownService.interestOptions[1]);
      }

      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){
        this.LocationsThreeBuildings.controls.at(2).get("buildinglimitMandatoryFlag").setValue(false);
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(2).get("BuildingLimitInsurance"), true);
        this.updateConditionalMandatoryBPPLRequired(this.LocationsThreeBuildings.controls.at(2));
      }
      this.buildingLimitCheck(this.LocationsThreeBuildings.controls.at(2));
    }
  }

  RemoveLocationOneBuilding(index) {
    this.LocationsOneBuildings.removeAt(index)
  }

  RemoveLocationTwoBuilding(index) {
    this.LocationsTwoBuildings.removeAt(index)
  }

  RemoveLocationThreeBuilding(index) {
    this.LocationsThreeBuildings.removeAt(index)
  }

  updateConditionalMandatoryBPPLRequired(inputFieldName) {
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([BusinssPersonalPropLmt]), true);
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([BuildingLimitInsurance]), false);

    this.dataMappingService.bppLimitChoosedFlag = true;
    if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){

    
      if(inputFieldName.get([BuildingLimitInsurance]).value!='' && inputFieldName.get([BuildingLimitInsurance]).value<1000){
        inputFieldName.get('BuildingLimitInsurance').setErrors({ 'buildingLimit': true });
      }
    }
  }

  updateConditionalMandatoryBLRequired(inputFieldName) {
   
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([BusinssPersonalPropLmt]), false);
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([BuildingLimitInsurance]), true);
    this.dataMappingService.bppLimitChoosedFlag = false;

    if (inputFieldName.get("Interest").value != this.dropDownService.interestBPPOptions[2]) {
      this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([BusinssPersonalPropLmt]), false);
    }
    if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])){

    
    if(inputFieldName.get([BusinssPersonalPropLmt]).value!='' && inputFieldName.get([BusinssPersonalPropLmt]).value<10000){
      inputFieldName.get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true })
    }
  }

  }

  updateConditionalMandatoryRequired(condition: boolean, inputFieldName) {
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([BuildingSquareFootage]), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([NumberOccupiedByOwners]), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([NumberVacant]), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([NumberLeasedToOthers]), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get([NumberOfUnits]), condition);
  }

  sprinkleredSelectedCheck(buildingroofCheck, building) {
    if (buildingroofCheck) {
      building.get('autoSprinkleredSystem').setValue("Y");
    } else {
      building.get('autoSprinkleredSystem').setValue("N");
    }
  }

  onTotalNumberOfUnitsChange(inputFieldName) {
    let totalUnits: number = inputFieldName.get('NumberOfUnits').value
      if (totalUnits <= 0 && inputFieldName.get("buldingLimitChecked").value) {
        setTimeout(() => {
          inputFieldName.get('NumberOfUnits').setErrors({ 'numberOfUnits': true });
        }, 0)
      } 
      this.calculateLeasedToOthers(inputFieldName);
  }

  calculateLeasedToOthers(inputFieldName) {
    let vacantUnits = inputFieldName.get('NumberVacant').value;
    let numberOfUnits = inputFieldName.get('NumberOfUnits').value;
    let occupiedUnits = inputFieldName.get('NumberOccupiedByOwners').value;
    let leaseToOthers: number = numberOfUnits - (parseInt(occupiedUnits) + parseInt(vacantUnits));
    if (leaseToOthers > 0) {
      inputFieldName.get('NumberLeasedToOthers').setValue(leaseToOthers.toString());
    }
    else {
      inputFieldName.get('NumberLeasedToOthers').setValue("0");
    }
    this.calculateSumOfUnits(inputFieldName);
  }

  calculateSumOfUnits(inputFieldName) {
    let vacantUnits = inputFieldName.get('NumberVacant').value;
    let numberOfUnits = inputFieldName.get('NumberOfUnits').value;
    let occupiedUnits = inputFieldName.get('NumberOccupiedByOwners').value;
    let leaseToOthers = inputFieldName.get('NumberLeasedToOthers').value;
    if (vacantUnits && numberOfUnits && numberOfUnits > 0 && occupiedUnits && leaseToOthers) {
      if (parseInt(numberOfUnits) === parseInt(occupiedUnits) + parseInt(vacantUnits) + parseInt(leaseToOthers)) {
        inputFieldName.get("sumOfUnitsValidFlag").setValue(true);
        inputFieldName.get('NumberOfUnits').setErrors({});
        inputFieldName.get('NumberOfUnits').updateValueAndValidity();
      }
      else {
        inputFieldName.get("sumOfUnitsValidFlag").setValue(false);
        inputFieldName.get(NumberOfUnits).setErrors({ 'sumOfUnits': true });
      }

    }
    else {
      inputFieldName.get("sumOfUnitsValidFlag").setValue(true);
    }
  }

  onbuildingroofclick(check, inputFieldName) {
    if (check) {
      inputFieldName.get("buildingroofselectedVal").setValue("Y");
    } else {
      inputFieldName.get("buildingroofselectedVal").setValue("N");
      inputFieldName.get("yearOfOldestUpdate").setValue("");
    }
    this.dataMappingService.isBuildingRoofSelectedVal = inputFieldName.get("buildingroofselectedVal").value;
    this.updateConditionalMandatoryyearOfOldestUpdateRequiredChange(check, inputFieldName);
  }

  updateConditionalMandatoryyearOfOldestUpdateRequired(condition,FormType,index) {
    if(FormType === "PropertyDetailsLocationOneForm"){
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls[index].get("yearOfOldestUpdate"),condition)
    }
    if(FormType === "PropertyDetailsLocationTwoForm"){
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls[index].get("yearOfOldestUpdate"),condition)
    }
    if(FormType === "PropertyDetailsLocationThreeForm"){
      this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls[index].get("yearOfOldestUpdate"),condition)
    }
 
  }
  updateConditionalMandatoryyearOfOldestUpdateRequiredChange(condition, inputFieldName) {
    this.uiService.updateFormControlValidatorBasedOnCondition(inputFieldName.get("yearOfOldestUpdate"), condition);
  }

  ShowFields(buildingLimit: number, index: number, inputFieldName) {
    if (buildingLimit) {
      inputFieldName.buldingLimitChecked = true;
    }
    else {
      inputFieldName.buldingLimitChecked = false;
    }
  }

  validateBasicBuildingLimit(buildingLimit: number, index: number, inputFieldName) {
    this.basicBuildingCoverLimitFailedFlag = false;
    this.uiService.builidingCoverageFlag = false;
    if (buildingLimit) {
      this.uiService.builidingCoverageFlag = true;
      this.processedInterestOptions = this.dropDownService.interestBPPOptions;
      if (this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
        if (buildingLimit && buildingLimit < 1000) {
          inputFieldName.get('BuildingLimitInsurance').setErrors({ 'buildingLimit': true });
        }
        else {
          if (inputFieldName.get("NumberOfUnits").value == 0) {
            inputFieldName.get('NumberOfUnits').setErrors({ 'numberOfUnits': true });
          }
        }
      }
      if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
        inputFieldName.get("buildinglimitMandatoryFlag").setValue(true)
        this.updateConditionalMandatoryBLRequired(inputFieldName);
        if (buildingLimit < 1000) {
          this.basicBuildingCoverLimitFailedFlag = true;
          inputFieldName.get('BuildingLimitInsurance').setErrors({ 'buildingLimit': true });
        }
        else {
          if (inputFieldName.get("NumberOfUnits").value == 0) {
            inputFieldName.get('NumberOfUnits').setErrors({ 'numberOfUnits': true });
          }
        }
      }
    }
  }

  validateBasicBusinessPersonalPropertyLimit(businessPersonalPropertyLimit: number, index: number, FormType, inputFieldName) {
    if (FormType === "PropertyDetailsLocationOneForm") {
      this.basicbusinessPersonalPropertyLimitFailedFlag = false;
      this.basicbusinessPersonalPropertyLimitSmallContractorFailedFlag = false;
      this.basicbusinessPersonalPropertyLimitNonSmallContractorFailedFlag = false;
      if (businessPersonalPropertyLimit) {
        this.processedInterestOptions = this.dropDownService.interestOptions;
        if (this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
          this.uiService.contractorToolsEquipmentBPP5000RequiredFlag = businessPersonalPropertyLimit < 5000 ? true : false;

          if (this.uiService.isInsuredSmallContractorSelectedVal == "Y") {
            if (businessPersonalPropertyLimit > 5000) {
              this.LocationsOneBuildings.controls.at(index).get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true });
            }
          }
        }

        if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
          inputFieldName.get("buildinglimitMandatoryFlag").setValue(false);
          this.buildinglimitMandatoryFlag = false;
          this.updateConditionalMandatoryBPPLRequired(inputFieldName);
          if (businessPersonalPropertyLimit < 10000) {
            this.LocationsOneBuildings.controls.at(index).get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true });
            this.basicbusinessPersonalPropertyLimitFailedFlag = true;
          }
        }
      }
      else if (this.LocationsOneBuildings.controls.at(index).get('BuildingLimitInsurance').value) {
        this.processedInterestOptions = this.dropDownService.interestBPPOptions;
      }
    }

    if (FormType === "PropertyDetailsLocationTwoForm") {
      this.basicbusinessPersonalPropertyLimitFailedFlagOne = false;
      this.basicbusinessPersonalPropertyLimitSmallContractorFailedFlag = false;
      this.basicbusinessPersonalPropertyLimitNonSmallContractorFailedFlag = false;
      if (businessPersonalPropertyLimit) {
        this.processedInterestOptions = this.dropDownService.interestOptions;
        if (this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
          this.uiService.contractorToolsEquipmentBPP5000RequiredFlag = businessPersonalPropertyLimit < 5000 ? true : false;

          if (this.uiService.isInsuredSmallContractorSelectedVal == "Y") {
            if (businessPersonalPropertyLimit > 5000) {
              this.LocationsTwoBuildings.controls.at(index).get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true });
            }
          }
        }

        if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
          inputFieldName.get("buildinglimitMandatoryFlag").setValue(false);
          this.buildinglimitMandatoryFlag = false;
          this.updateConditionalMandatoryBPPLRequired(inputFieldName);
          if (businessPersonalPropertyLimit < 10000) {
            this.LocationsTwoBuildings.controls.at(index).get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true });
            this.basicbusinessPersonalPropertyLimitFailedFlagOne = true;
          }
        }
      }
      else if (this.LocationsTwoBuildings.controls.at(index).get('BuildingLimitInsurance').value) {
        this.processedInterestOptions = this.dropDownService.interestBPPOptions;
      }
    }
    if (FormType === "PropertyDetailsLocationThreeForm") {
      this.basicbusinessPersonalPropertyLimitFailedFlagOne = false;
      this.basicbusinessPersonalPropertyLimitSmallContractorFailedFlag = false;
      this.basicbusinessPersonalPropertyLimitNonSmallContractorFailedFlag = false;
      if (businessPersonalPropertyLimit) {
        this.processedInterestOptions = this.dropDownService.interestOptions;
        if (this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
          this.uiService.contractorToolsEquipmentBPP5000RequiredFlag = businessPersonalPropertyLimit < 5000 ? true : false;

          if (this.uiService.isInsuredSmallContractorSelectedVal == "Y") {
            if (businessPersonalPropertyLimit > 5000) {
              this.LocationsThreeBuildings.controls.at(index).get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true });
            }
          }
        }

        if (!(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])) {
          inputFieldName.get("buildinglimitMandatoryFlag").setValue(false);
          this.buildinglimitMandatoryFlag = false;
          this.updateConditionalMandatoryBPPLRequired(inputFieldName);
          if (businessPersonalPropertyLimit < 10000) {
            this.LocationsThreeBuildings.controls.at(index).get('BusinssPersonalPropLmt').setErrors({ 'businssPersonalPropLmt': true });
            this.basicbusinessPersonalPropertyLimitFailedFlagOne = true;
          }
        }
      }
      else if (this.LocationsThreeBuildings.controls.at(index).get('BuildingLimitInsurance').value) {
        this.processedInterestOptions = this.dropDownService.interestBPPOptions;
      }
    }
  }
  validateYearOfConstructionLimit(yearofConstruction: number, building) {
    let currentYear = new Date().getFullYear()
    if (yearofConstruction > currentYear) {
      building.get(YearBuilt).setValue("");
      building.get("yearofBuiltFailedFlag").setValue(true);
    }
    else {
      building.get("yearofBuiltFailedFlag").setValue(false);
    }
  }

  onInterestChange(interestValue: string, index, FormType) {
    if (FormType === "PropertyDetailsLocationOneForm") {
      if ((this.LocationsOneBuildings.controls.at(index).get("BuildingLimitInsurance").value) && (this.LocationsOneBuildings.controls.at(index).get("Interest").value != this.dropDownService.interestOptions[3])) {
        this.buildingSquareFootageMandatoryFlagLocationOne = true;
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(index).get('BuildingSquareFootage'), true);
      }
      else {
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(index).get('BuildingSquareFootage'), false);
        this.buildingSquareFootageMandatoryFlagLocationOne = false;
      }
      if (this.LocationsOneBuildings.controls.at(index).get("Interest").value === this.dropDownService.interestOptions[2] && !(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) && !(this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS])) {
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(index).get('BusinssPersonalPropLmt'), true);
        this.onIntrestChangeMandatoryBppLocationOne = true
      }
      else {
        if (this.LocationsOneBuildings.controls.at(index).get('BuildingLimitInsurance').value != '')
          this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsOneBuildings.controls.at(index).get('BusinssPersonalPropLmt'), false);
        this.onIntrestChangeMandatoryBppLocationOne = false
      }
    }

    if (FormType === "PropertyDetailsLocationTwoForm") {
      if ((this.LocationsTwoBuildings.controls.at(index).get("BuildingLimitInsurance").value) && (this.LocationsTwoBuildings.controls.at(index).get("Interest").value != this.dropDownService.interestOptions[3])) {
        this.buildingSquareFootageMandatoryFlagLocationTwo = true;
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(index).get('BuildingSquareFootage'), true);
      }
      else {
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(index).get('BuildingSquareFootage'), false);
        this.buildingSquareFootageMandatoryFlagLocationTwo = false;
      }
      if (this.LocationsTwoBuildings.controls.at(index).get("Interest").value === this.dropDownService.interestOptions[2] && !(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) && !(this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS])) {
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(index).get('BusinssPersonalPropLmt'), true);
        this.onIntrestChangeMandatoryBppLocationTwo = true
      }
      else {
        if (this.LocationsTwoBuildings.controls.at(index).get('BuildingLimitInsurance').value != '')
          this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsTwoBuildings.controls.at(index).get('BusinssPersonalPropLmt'), false);
        this.onIntrestChangeMandatoryBppLocationTwo = false

      }
    }

    if (FormType === "PropertyDetailsLocationThreeForm") {
      if ((this.LocationsThreeBuildings.controls.at(index).get("BuildingLimitInsurance").value) && (this.LocationsThreeBuildings.controls.at(index).get("Interest").value != this.dropDownService.interestOptions[3])) {
        this.buildingSquareFootageMandatoryFlagLocationThree = true;
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(index).get('BuildingSquareFootage'), true);
      }
      else {
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(index).get('BuildingSquareFootage'), false);
        this.buildingSquareFootageMandatoryFlagLocationThree = false;
      }
      if (this.LocationsThreeBuildings.controls.at(index).get("Interest").value === this.dropDownService.interestOptions[2] && !(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) && !(this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS])) {
        this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(index).get('BusinssPersonalPropLmt'), true);
        this.onIntrestChangeMandatoryBppLocationThree = true
      }
      else {
        if (this.LocationsThreeBuildings.controls.at(index).get('BuildingLimitInsurance').value != '')
          this.uiService.updateFormControlValidatorBasedOnCondition(this.LocationsThreeBuildings.controls.at(index).get('BusinssPersonalPropLmt'), false);
        this.onIntrestChangeMandatoryBppLocationThree = false
      }
    }
  }

  ngOnDestroy() {
    this.uiService.LocationOneTabVisited = true;
    this.uiService.LocationTwoTabVisited = false;
    this.uiService.LocationThreeTabVisited = false;
    this.uiService.InteractionType = 1;
    this.uiService.LocationOneFlag = true;
    this.uiService.LocationTwoFlag = false;
    this.uiService.LocationThreeFlag = false;
    this.RemoveMoveNextSubscription.unsubscribe();
    this.RemoveBuildingsSubscription.unsubscribe();
    this.RemoveTabsSubscription.unsubscribe();
    this.removeSqFootageDeclinationLocSubscription.unsubscribe();
  }

  _doClearUnSavedData(FormArray,num) {
    console.log("form array inside doClearUnsavedData",FormArray)
    const selectedDmgPremRentYouLimitValue = this.dataMappingService.convertToDollarFormat(Number(this.dataService.BPBuilding.dmgPremRentYouLimit));
    FormArray.controls.forEach(element => {
      element.get("BuildingLimitInsurance").setValue("");
      element.get("BusinssPersonalPropLmt").setValue("");
      element.get("OptionalPropertyDedu").setValue(this.dataMappingService.convertToDollarFormat(Number('500')));
      element.get("YearBuilt").setValue(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[num-1].insurableObject.building.yearBuilt);
      element.get("ConstructionType").setValue(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[num-1].insurableObject.building.constructionType);
      element.get("DistanceFromFireStation").setValue(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[num-1].insurableObject.building.distanceToFireStation);
      element.get("DistToNearestHydrant").setValue(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[num-1].insurableObject.building.distanceToFireHydrant);
      element.get("cRoof").setValue("");
      element.get("cPlumbing").setValue("");
      element.get("cHeating").setValue("");
      element.get("cWaterHeater").setValue("");
      element.get("cElectrical").setValue("");
      element.get("yearOfOldestUpdate").setValue("");
      element.get("fireAlarmALocalAlarm").setValue(this.dataMappingService.YNToBool(""));
      element.get("CrmProtAlmALocalAlm").setValue(this.dataMappingService.YNToBool(""));
      element.get("fireAlarmACenStnAlarm").setValue(this.dataMappingService.YNToBool(""));
      element.get("CrmProtAlmACenStAlm").setValue(this.dataMappingService.YNToBool(""));
      element.get("fireAlarmABatopSmkDet").setValue(this.dataMappingService.YNToBool(""));
      element.get("fireAlarmAHrdwrSmkDet").setValue(this.dataMappingService.YNToBool(""));
      element.get("autoSprinkleredSystem").setValue("");
      element.get("BuildingSquareFootage").setValue("");
      element.get("NumberOfUnits").setValue(this.dataService.marketplace_prefill_response.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[num-1].insurableObject.building.numUnits);
      element.get("NumberOccupiedByOwners").setValue("");
      element.get("NumberVacant").setValue("");
      element.get("NumberLeasedToOthers").setValue("");
      element.get("dmgPremRentYouLimit").setValue(selectedDmgPremRentYouLimitValue);
      element.get("restaurantEndorsementSelectedVal").setValue('');
      element.get("apartmentBuildingEndorsementSelectedVal").setValue('');
      element.get("buildingroofselectedVal").setValue('N');
      element.get("buldingLimitChecked").setValue(false);
      element.get("sumOfUnitsValidFlag").setValue(true);
    });
  }

  _doSelectLocationTab(type, tab) {
    let object = Object.keys(this.dataMappingService.currentForm.controls);
    if (this.dataMappingService.currentForm.get("LocationTwo") && this.dataMappingService.currentForm.invalid && object[0] != tab) {
      this.uiService.LocationTwoTab = false;
      this.uiService.LocationTwoTabVisited = false;
      this._doClearUnSavedData(this.LocationsTwoBuildings,2);
    }
    if (this.dataMappingService.currentForm.get("LocationThree") && this.dataMappingService.currentForm.invalid && object[0] != tab) {
      this.uiService.LocationThreeTab = false;
      this.uiService.LocationThreeTabVisited = false;
      this._doClearUnSavedData(this.LocationsThreeBuildings,3);
    }
    if (type === 'one') {
      this.uiService.LocationOneFlag = true;
      this.uiService.LocationTwoFlag = false;
      this.uiService.LocationThreeFlag = false;
      this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
      let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
      this.uiService.InteractionType = 1;

      if (BuildingsList.length > 1) {
        this.uiService.LocationOneFlag = true;
        this.uiService.LocationTwoFlag = false;
        this.uiService.LocationThreeFlag = false;
        this.LocationDataPreFill(BuildingsList, '1');
      }
      else {
        this.LocationDataPreFill(BuildingsList, '1');
      }
    }
    else if (type === 'two') {
      this.uiService.LocationOneFlag = false;
      this.uiService.LocationTwoFlag = true;
      this.uiService.LocationThreeFlag = false;
      this.dataMappingService.currentForm = this.PropertyDetailsLocationTwoForm;
      let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
      this.uiService.InteractionType = 2;

      if (BuildingsList.length > 1) {
        this.uiService.LocationOneFlag = false;
        this.uiService.LocationTwoFlag = true;
        this.uiService.LocationThreeFlag = false;
        this.LocationDataPreFill(BuildingsList, '2');
      }
      else {
        this.LocationDataPreFill(BuildingsList, '2');
      }
    }
    else if (type === 'three') {
      this.uiService.LocationOneFlag = false;
      this.uiService.LocationTwoFlag = false;
      this.uiService.LocationThreeFlag = true;
      this.dataMappingService.currentForm = this.PropertyDetailsLocationThreeForm;
      let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
      this.uiService.InteractionType = 3;

      if (BuildingsList.length > 1) {
        this.uiService.LocationOneFlag = false;
        this.uiService.LocationTwoFlag = false;
        this.uiService.LocationThreeFlag = true;
        this.LocationDataPreFill(BuildingsList, '3');
      }
      else {
        this.LocationDataPreFill(BuildingsList, '3');
      }
    }
    else {
      this.uiService.LocationOneFlag = true;
      this.uiService.LocationTwoFlag = false;
      this.uiService.LocationThreeFlag = false;
    }
  }

  locationOneApartmentBuildingEndorsementValChange(flag, location) {
    if (flag == true) {
      if (location == '0') {
        this.apartmentLocationOneEndorsementSelectedVal = 'Y';
        this.dataMappingService.apartmentLocationOneEndorsementSelectedVal = this.apartmentLocationOneEndorsementSelectedVal;
      }
      if (location == '1') {
        this.apartmentLocationTwoEndorsementSelectedVal = 'Y';
        this.dataMappingService.apartmentLocationTwoEndorsementSelectedVal = this.apartmentLocationTwoEndorsementSelectedVal;
      }
      if (location == '2') {
        this.apartmentLocationThreeEndorsementSelectedVal = 'Y';
        this.dataMappingService.apartmentLocationThreeEndorsementSelectedVal = this.apartmentLocationThreeEndorsementSelectedVal;
      }

    }
    else {
      if (location == '0') {
        this.apartmentLocationOneEndorsementSelectedVal = 'N';
        this.dataMappingService.apartmentLocationOneEndorsementSelectedVal = this.apartmentLocationOneEndorsementSelectedVal;
      }
      if (location == '1') {
        this.apartmentLocationTwoEndorsementSelectedVal = 'N';
        this.dataMappingService.apartmentLocationTwoEndorsementSelectedVal = this.apartmentLocationTwoEndorsementSelectedVal;
      }
      if (location == '2') {
        this.apartmentLocationThreeEndorsementSelectedVal = 'N';
        this.dataMappingService.apartmentLocationThreeEndorsementSelectedVal = this.apartmentLocationThreeEndorsementSelectedVal;
      }
    }
  }

  restaurantEndorsementValChange(flag, location) {
    if (flag == true) {
      if (location == '0') {
        this.restaurantLocationOneEndorsementSelectedVal = "Y";
        this.dataMappingService.restaurantLocationOneEndorsementSelectedVal = this.restaurantLocationOneEndorsementSelectedVal;
      }
      if (location == '1') {
        this.restaurantLocationTwoEndorsementSelectedVal = "Y";
        this.dataMappingService.restaurantLocationTwoEndorsementSelectedVal = this.restaurantLocationTwoEndorsementSelectedVal;
      }
      if (location == '2') {
        this.restaurantLocationThreeEndorsementSelectedVal = "Y";
        this.dataMappingService.restaurantLocationThreeEndorsementSelectedVal = this.restaurantLocationThreeEndorsementSelectedVal;
      }
    }
    else {
      if (location == '0') {
        this.restaurantLocationOneEndorsementSelectedVal = "N";
        this.dataMappingService.restaurantLocationOneEndorsementSelectedVal = this.restaurantLocationOneEndorsementSelectedVal;
      }
      if (location == '1') {
        this.restaurantLocationTwoEndorsementSelectedVal = "N";
        this.dataMappingService.restaurantLocationTwoEndorsementSelectedVal = this.restaurantLocationTwoEndorsementSelectedVal;
      }
      if (location == '2') {
        this.restaurantLocationThreeEndorsementSelectedVal = "N";
        this.dataMappingService.restaurantLocationThreeEndorsementSelectedVal = this.restaurantLocationThreeEndorsementSelectedVal;
      }
    }
  }

  _doPrefillOtherValues(value, building) {
    if (value === 'one') {
      this.LocationsOneBuildings.controls.forEach((element) => {
        element.get("DistanceFromFireStation").setValue(building.get("DistanceFromFireStation").value)
      })
    }
    else if (value === 'two') {
      this.LocationsTwoBuildings.controls.forEach((element) => {
        element.get("DistanceFromFireStation").setValue(building.get("DistanceFromFireStation").value)
      })
    }
    else {
      this.LocationsThreeBuildings.controls.forEach((element) => {
        element.get("DistanceFromFireStation").setValue(building.get("DistanceFromFireStation").value)
      })
    }
  }

  _doPrefillDFHValues(value, building) {
    if (value === 'one') {
      this.LocationsOneBuildings.controls.forEach((element) => {
        element.get("DistToNearestHydrant").setValue(building.get("DistToNearestHydrant").value)
      })
    }
    else if (value === 'two') {
      this.LocationsTwoBuildings.controls.forEach((element) => {
        element.get("DistToNearestHydrant").setValue(building.get("DistToNearestHydrant").value)
      })
    }
    else {
      this.LocationsThreeBuildings.controls.forEach((element) => {
        element.get("DistToNearestHydrant").setValue(building.get("DistToNearestHydrant").value)
      })
    }
  }

  // PHOTOGRAPHY
  onPsPhotoServiceClick(flag, building) {
    if (flag) {
      building.get("psPhotoServiceSelectedVal").setValue("Y");
    } else {
      building.get("psPhotoServiceSelectedVal").setValue("N");
    }

    building.get("photography").setValue(building.get("psPhotoServiceSelectedVal").value);

  }

  // BARBER / BEAUTICIAN
  onPsBarberBeauticianClick(flag, building) {
    if (flag) {
      building.get("psBarberBeauticianSelectedVal").setValue("Y");
    } else {
      building.get("psBarberBeauticianSelectedVal").setValue("N");
    }
    this.uiService.processingServiceBarBeautSelectedFlag = building.get("psBarberBeauticianSelectedVal").value;

    building.get("brbBeutPrsEnhPrfLb").setValue(building.get("psBarberBeauticianSelectedVal").value);
    let professionalClassCategory = this.uiService.processingBarberService71332Flag ? "Barbers" : "Beauticians";
    building.get("professionalClass").setValue(professionalClassCategory);

    this.updatePsBarberBeauticianRequired(professionalClassCategory, this.psBarberBeauticianSelectedVal == 'Y', building)
  }
  updatePsBarberBeauticianRequired(professionalClass: string, condition: boolean, building) {
    if (professionalClass == "Barbers") {
      this.uiService.updateFormControlValidatorBasedOnCondition(building.get("noOfFullTimeBarbers"), condition);
      this.uiService.updateFormControlValidatorBasedOnCondition(building.get("noOfPartTimeBarbers"), condition);
    }
    if (professionalClass == "Beauticians") {
      this.uiService.updateFormControlValidatorBasedOnCondition(building.get("noOfFlTmBuPrEnPf"), condition);
      this.uiService.updateFormControlValidatorBasedOnCondition(building.get("noOfPtTmBuPrEnPf"), condition);
    }
  }

  // VETERINARIAN
  onveterinarianEndorsementClick(flag, building) {
    if (flag) {
      building.get("veterinarProfLiab").setValue("Y");
    } else {
      building.get("veterinarProfLiab").setValue("N");
    }
    this.uiService.veterinarianEndorsementSelectedFlag = building.get("veterinarianEndorsementSelectedVal").value;
  }


  onVeterinarianChange(building) {
    building.get("numberOfVeterinarianFailFlag").setValue(false);
    let total: number = parseInt(building.get('numberOfVeterinarian').value);
    if (total <= 0) {
      building.get("numberOfVeterinarian").setValue("");
      building.get("numberOfVeterinarianFailFlag").setValue(true);
    }
  }

  onSquareFootageChange(building) {
    building.get("totalSquareFootageFlag").setValue(false);
    let squareFootage: number = parseInt(building.get('BuildingSquareFootage').value);
    if (squareFootage <= 0) {
      building.get("BuildingSquareFootage").setValue("");
      building.get("totalSquareFootageFlag").setValue(true);
    }
  }
  
  _doRemoveLocations(location){
      if(location == "1"){
        let x:any = [];
        let y:any = [];
        this.dataService.BPLocations.forEach((ele)=>{
          if(ele.referenceObjectId != location){
             x.push(ele)
          }
        })
        this.dataService.businessOwners.BPLocations.forEach((ele)=>{
          if(ele.referenceObjectId != location){
             y.push(ele)
          }
        })
        if(x.length == 1){
          x[0].referenceObjectId = "1";
          y[0].referenceObjectId = "1";
          this.PropertyDetailsLocationOneForm.patchValue(this.PropertyDetailsLocationTwoForm.value);
          this.PropertyDetailsLocationTwoForm.reset({});
          this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
          this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
            this.RemoveLocationBuildingsFromJson(element.location, element.buildingNo)
          });
         this.dataService.businessOwners.BPBuilding.forEach((ele)=>{
            ele.locationId ="1"
         })
         this._doPrefillLocationOne();
         console.log(this.dataService.current_business_request,"current request after removing location")
        }
        else if(x.length == 2){
          x[0].referenceObjectId = "1";
          x[1].referenceObjectId = "2";
          y[0].referenceObjectId = "1";
          y[1].referenceObjectId = "2";
          this.PropertyDetailsLocationOneForm.patchValue(this.PropertyDetailsLocationTwoForm.value);
          this.PropertyDetailsLocationTwoForm.patchValue(this.PropertyDetailsLocationThreeForm.value);
          this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
          this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
            this.RemoveLocationBuildingsFromJson(element.location, element.buildingNo)
         });
         this.dataService.businessOwners.BPBuilding.forEach((ele)=>{
           if(ele.locationId =="2"){
            ele.locationId = "1"
           }
           if(ele.locationId =="3"){
            ele.locationId = "2"
           }
         })
         this._doPrefillLocationOne();
        }
        this.dataService.BPLocations= x;
        this.dataService.businessOwners.BPLocations=y;
      }
      else if(location == "2") {
        let x:any = [];
        let y:any = [];
        this.dataService.BPLocations.forEach((ele)=>{
          if(ele.referenceObjectId != location){
             x.push(ele)
          }
        })
        this.dataService.businessOwners.BPLocations.forEach((ele)=>{
          if(ele.referenceObjectId != location){
             y.push(ele)
          }
        })
        if(x.length == 1){
          this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
            this.RemoveLocationBuildingsFromJson(element.location, element.buildingNo)
         });
         this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
         this._doPrefillLocationOne();
        }
       else if(x.length == 2){
          x[1].referenceObjectId = "2";
          y[1].referenceObjectId = "2";
          this.PropertyDetailsLocationTwoForm.patchValue(this.PropertyDetailsLocationThreeForm.value);
          this.dataMappingService.currentForm = this.PropertyDetailsLocationTwoForm;

          this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
            this.RemoveLocationBuildingsFromJson(element.location, element.buildingNo)
         });
         this.dataService.businessOwners.BPBuilding.forEach((ele)=>{
           if(ele.locationId =="3"){
            ele.locationId = "2"
           }
         })
         this._doPrefillLocationThree();
        }
        this.dataService.BPLocations= x;
        this.dataService.businessOwners.BPLocations=y;
      }
      else {
        let x:any = [];
        let y:any = [];
        this.dataService.BPLocations.forEach((ele)=>{
          if(ele.referenceObjectId != location){
             x.push(ele)
          }
        })
        this.dataService.businessOwners.BPLocations.forEach((ele)=>{
          if(ele.referenceObjectId != location){
             y.push(ele)
          }
        })
        this.dataMappingService.currentForm = this.PropertyDetailsLocationThreeForm;
        this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
          this.RemoveLocationBuildingsFromJson(element.location, element.buildingNo)
       });
       this.dataService.BPLocations= x;
       this.dataService.businessOwners.BPLocations=y;
       this._doPrefillLocationOne();
      }
   }

   _doPrefillLocationTwo() {
    this.uiService.LocationOneFlag = false;
      this.uiService.LocationTwoFlag = true;
      this.uiService.LocationThreeFlag = false;
      this.dataMappingService.currentForm = this.PropertyDetailsLocationTwoForm;
      let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
      this.uiService.InteractionType = 2;

      if (BuildingsList.length > 1) {
        this.uiService.LocationOneFlag = false;
        this.uiService.LocationTwoFlag = true;
        this.uiService.LocationThreeFlag = false;
        this.LocationDataPreFill(BuildingsList, '2');
      }
      else {
        this.LocationDataPreFill(BuildingsList, '2');
      }
   }

   _doPrefillLocationThree() {
    this.uiService.LocationOneFlag = false;
    this.uiService.LocationTwoFlag = false;
    this.uiService.LocationThreeFlag = true;
    this.dataMappingService.currentForm = this.PropertyDetailsLocationThreeForm;
    let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
    this.uiService.InteractionType = 3;

    if (BuildingsList.length > 1) {
      this.uiService.LocationOneFlag = false;
      this.uiService.LocationTwoFlag = false;
      this.uiService.LocationThreeFlag = true;
      this.LocationDataPreFill(BuildingsList, '3');
    }
    else {
      this.LocationDataPreFill(BuildingsList, '3');
    }
   }

   _doPrefillLocationOne(){
    this.uiService.LocationOneFlag = true;
      this.uiService.LocationTwoFlag = false;
      this.uiService.LocationThreeFlag = false;
      this.dataMappingService.currentForm = this.PropertyDetailsLocationOneForm;
      let BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
      this.uiService.InteractionType = 1;

      if (BuildingsList.length > 1) {
        this.uiService.LocationOneFlag = true;
        this.uiService.LocationTwoFlag = false;
        this.uiService.LocationThreeFlag = false;
        this.LocationDataPreFill(BuildingsList, '1');
      }
      else {
        this.LocationDataPreFill(BuildingsList, '1');
      }
   }

   _doRemoveDeclinedBuildings() {
   this.RemoveBuildingsSubscription= this.uiService.RemoveDeclinedBuildings.subscribe((data)=>{
      let locationId = this.uiService.SqftDeclineBuildings[0].location;
      if(this.dataService.BPLocations.length === 1 && this.uiService.SqftDeclineBuildings.length == this.uiService.SqftAllBuildings.length){
        location.reload();
      }
      else {
        if(this.uiService.SqftDeclineBuildings.length == this.uiService.SqftAllBuildings.length){
          console.log("Removing location")
          this._doRemoveLocations(locationId);
        }
        else {
          if(locationId == '1'){
            this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
               this.RemoveLocationOneBuilding(element.buildingNo - 1);
               this.RemoveBuildingsFromJson(element.location, element.buildingNo)
            });
            if(this.uiService.addedLocations.length < 2){
              this.router.navigate(['/liability-details']);
            }
            else {
              this.uiService.LocationTwoTab = true;
              this.uiService.LocationTwoTabVisited = true;
              this.uiService.LocationOneFlag = false;
              this.uiService.LocationTwoFlag = true;
              this.uiService.LocationThreeFlag = false;
              this.uiService.SqftFlag = false;
              this._doPrefillLocationTwo(); 
            }
            
          }
          else if(locationId == '2'){
            this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
               this.RemoveLocationTwoBuilding(element.buildingNo - 1);
               this.RemoveBuildingsFromJson(element.location, element.buildingNo)
              
            });
  
            if(this.uiService.addedLocations.length < 3) {
              this.router.navigate(['/liability-details']);
            }
            else {
              this.uiService.LocationThreeTab = true;
              this.uiService.LocationThreeTabVisited = true;
              this.uiService.LocationOneFlag = false;
              this.uiService.LocationTwoFlag = false;
              this.uiService.LocationThreeFlag = true;
              this.uiService.SqftFlag = false;
              this._doPrefillLocationThree(); 
            }
          }
          else if(locationId == '3'){
            this.uiService.SqftDeclineBuildings.forEach((element:any, index) => {
               this.RemoveLocationThreeBuilding(element.buildingNo - 1);
               this.RemoveBuildingsFromJson(element.location, element.buildingNo)
            });
            this.uiService.SqftFlag = false;
            this.router.navigate(['/liability-details']);
          }
        }
      }
    })
   }
   removeDeclinedLocationOnDeclination(){
    this.removeSqFootageDeclinationLocSubscription=this.uiService.removeDeclinedLocationOnSquareFootageDeclination.subscribe((data)=>{
    
      if (this.uiService.addedLocations.length > 1) {
       
        if (Number(this.uiService.idToDeleteLocation) == 1) {
          this.dataMappingService.originalClassCodeObj.classcode = this.dataMappingService.originalClassCodeObj.classcode2;
          this.dataMappingService.originalClassCodeObj.description = this.dataMappingService.originalClassCodeObj.description2;
          this.dataMappingService.originalClassCodeObj.propertytype = this.dataMappingService.originalClassCodeObj.propertytype2;
  
          this.dataMappingService.originalClassCodeObj.classcode2 = this.dataMappingService.originalClassCodeObj.classcode3;
          this.dataMappingService.originalClassCodeObj.description2 = this.dataMappingService.originalClassCodeObj.description3;
          this.dataMappingService.originalClassCodeObj.propertytype2 = this.dataMappingService.originalClassCodeObj.propertytype3;
          
          this.dataMappingService.originalClassCodeObj.classcode3 = "";
          this.dataMappingService.originalClassCodeObj.description3 = "";
          this.dataMappingService.originalClassCodeObj.propertytype3 = "";
        
        }
  
        if (this.uiService.addedLocations == 3) {
          this.dataMappingService.originalClassCodeObj.classcode3 = "";
          this.dataMappingService.originalClassCodeObj.description3 = "";
          this.dataMappingService.originalClassCodeObj.propertytype3 = "";
        
        }
  
        if (Number(this.uiService.idToDeleteLocation) == 2 && this.uiService.addedLocations.length == 3) {
  
          this.dataMappingService.originalClassCodeObj.classcode2 = this.dataMappingService.originalClassCodeObj.classcode3;
          this.dataMappingService.originalClassCodeObj.description2 = this.dataMappingService.originalClassCodeObj.description3;
          this.dataMappingService.originalClassCodeObj.propertytype2 = this.dataMappingService.originalClassCodeObj.propertytype3;
          this.dataMappingService.originalClassCodeObj.classcode3 = "";
          this.dataMappingService.originalClassCodeObj.description3 = "";
          this.dataMappingService.originalClassCodeObj.propertytype3 = "";
         
        }
  
        if (this.uiService.addedLocations.length == 2) {
          this.uiService.addedLocations.splice(1, 1)
        } else if (this.uiService.addedLocations.length == 3) {
          this.uiService.addedLocations.splice(2, 1)
        }
  
      }
     
   this.deleteAssociatedBuildings(Number(this.uiService.idToDeleteLocation)-1);
   this.deleteAssociatedBPLocation(this.uiService.idToDeleteLocation);
      console.log(this.dataService.current_business_request,"inside removeDeclinedLocationOnDeclination")
            console.log(this.uiService.addedLocations,"added loc")

      if (this.uiService.addedLocations.length == 1) {
       
        if(this.uiService.idToDeleteLocation=="1"){
          console.log("navigate to property")
          this.moveToTabOne()
        }else{
          console.log("navigate to liability")
          this.router.navigate(['/liability-details']);
        }
      } else if (this.uiService.addedLocations.length == 2) {
       if(this.uiService.idToDeleteLocation=="3"){
        this.router.navigate(['/liability-details']);
       }else{
        this.moveToTabOne()
       }
      }
    })


   }

   deleteAssociatedBPLocation(value){
    let x=[];
    this.dataService.businessOwners.BPLocations.forEach((data)=>{
      if(data.referenceObjectId!=value){
        x.push(data);
      }
    })
    x.forEach((d,index)=>{
      d.referenceObjectId=String(index+1)
    })
    this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations=x;
   }
   deleteAssociatedBuildings(index){
    let res=[];
 
    this.dataService.businessOwners.BPBuilding.forEach((d)=>{
      if(d.locationId!=index+1){
        res.push(d);
      }
    })

    if(index==0){
      this.dataMappingService.LocationOneBuildings=[];
      this.dataMappingService.LocationTwoBuildings.forEach((d)=>{
        this.dataMappingService.LocationOneBuildings.push(d);
      })
      this.dataMappingService.LocationTwoBuildings=[];
      this.dataMappingService.LocationThreeBuildings.forEach((d)=>{
        this.dataMappingService.LocationTwoBuildings.push(d);
      })
      this.dataMappingService.LocationThreeBuildings=[];
      res.forEach((d,i)=>{
        if(d.locationId=="2"){
          res[i].locationId="1";
        }
        if(d.locationId=="3"){
          res[i].locationId="2";
        }
       
       console.log(res,i);
      })
    }
    if(index==1){
      this.dataMappingService.LocationTwoBuildings=[];
      this.dataMappingService.LocationThreeBuildings.forEach((d)=>{
        this.dataMappingService.LocationTwoBuildings.push(d);
      })
      this.dataMappingService.LocationThreeBuildings=[];
      res.forEach((d,i)=>{
        if(d.locationId=="3"){
          res[i].locationId="2";
        }
      })
    }
    console.log(res,"filtered response")
    if(res.length==0){
      res.push(this.dataService.BPBuilding);
    }
    if(this.uiService.addedLocations.length>=1 && this.dataService.businessOwners.BPBuilding.length<=1){
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0]=this.uiService.backupBPBuilding;
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].DistToNearestHydrant=res[0].DistToNearestHydrant;
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].DistanceFromFireStation=res[0].DistanceFromFireStation;
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].PublicProtFireClas=res[0].PublicProtFireClas;
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].YearBuilt=res[0].YearBuilt;
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].ConstructionType=res[0].ConstructionType;
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].NumberOfUnits=res[0].NumberOfUnits;

    }else{
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding=res;
    }
  
  }
   RemoveLocationBuildingsFromJson(location, buildingId) {
    let FinalBuildings = [];
    this.dataService.businessOwners.BPBuilding.forEach((ele)=>{
      if(ele.locationId != location){
        FinalBuildings.push(ele);
      }
    })
    this.dataService.businessOwners.BPBuilding= FinalBuildings;
   }
   RemoveBuildingsFromJson(location, buildingId) {
    let LocationOne =[];
    let LocationTwo =[];
    let LocationThree=[];

    if(location == '1'){
      LocationOne = this.dataMappingService.LocationOneBuildings.filter((ele, index)=>{
        return ele.locationId== location && ele.buildingId != buildingId
      })
      LocationTwo = this.dataMappingService.LocationTwoBuildings;
      LocationThree = this.dataMappingService.LocationThreeBuildings;
      this.dataMappingService.LocationOneBuildings = LocationOne;
    }
    if(location == '2'){
      LocationTwo = this.dataMappingService.LocationTwoBuildings.filter((ele, index)=>{
        return ele.locationId== location && ele.buildingId != buildingId
      })
      LocationOne = this.dataMappingService.LocationOneBuildings;
      LocationThree = this.dataMappingService.LocationThreeBuildings;
      this.dataMappingService.LocationTwoBuildings = LocationOne;
    }
    if(location == '3'){
      LocationThree = this.dataMappingService.LocationThreeBuildings.filter((ele, index)=>{
        return ele.locationId== location && ele.buildingId != buildingId
      })
      LocationOne = this.dataMappingService.LocationOneBuildings;
      LocationTwo = this.dataMappingService.LocationTwoBuildings;
      this.dataMappingService.LocationThreeBuildings = LocationThree;
    }
    this.dataService.quote_policy.BusinessOwners.BPBuilding = LocationOne.concat(LocationTwo, LocationThree);
   }


   _doTabMove() {
   this.RemoveMoveNextSubscription =  this.uiService.MoveNextTab.subscribe((data)=>{
      if ((this.uiService.SqftFlag && this.uiService.addedLocations.length < 2 && this.uiService.LocationOneTabVisited) ||
      (this.uiService.SqftFlag && this.uiService.addedLocations.length < 3 && this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited) ||
        (this.uiService.SqftFlag && this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited && this.uiService.LocationThreeTabVisited)) 
      {
          this.router.navigate(['/liability-details']);
      }
      else if (this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited && this.uiService.SqftFlag) {
        this.uiService.LocationThreeTab = true;
          this.uiService.LocationThreeTabVisited = true;
          this.uiService.LocationOneFlag = false;
          this.uiService.LocationTwoFlag = false;
          this.uiService.LocationThreeFlag = true;
          this.uiService.LocationTabs.next("3");
      }
      else if (this.uiService.LocationOneTabVisited && this.uiService.SqftFlag) {
        console.log("move next tab")
        this.uiService.LocationTwoTab = true;
        this.uiService.LocationTwoTabVisited = true;
        this.uiService.LocationOneFlag = false;
        this.uiService.LocationTwoFlag = true;
        this.uiService.LocationThreeFlag = false;
        this.uiService.LocationTabs.next("2");
      }
      else {
        this.uiService.LocationOneTab = true;
        this.uiService.LocationOneFlag = true;
        this.uiService.LocationTwoFlag = false;
        this.uiService.LocationThreeFlag = false;
      }
    })
   
   }

   moveToTabOne(){
    this.uiService.LocationOneTabVisited = true;
    this.uiService.LocationTwoTabVisited = false;
    this.uiService.LocationThreeTabVisited = false;
    this.uiService.LocationOneTab = true;
    this.uiService.LocationOneFlag = true;
    this.uiService.LocationTwoFlag = false;
    this.uiService.LocationThreeFlag = false;
    this.uiService.InteractionType = 1;
    this.uiService.LocationTabs.next("1");
    const BuildingsList: any = this.dataService.quote_policy.BusinessOwners.BPBuilding;
    this.LocationDataPreFill(BuildingsList, '1');

   }
}
