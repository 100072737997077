import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { DropdownService } from "./dropdown.service";
import { BusinessAttribute } from "@app/core/enums/business-attributes.enum";
import { cReconstrConstr, resAuRepMnufcProcOcup, recentPolCancld, otherLocations, cCompPolicy, applicantLawsuit, cReasons, ListOfPolicies, BPCompanionPolicy, CCompPolicyType, CCompPolicyNumberRef, PlayGroundExists, RiseToClaim, SwimmingPoolExists, foreignProducts, CRecQues, CDescProducts, Nfpa96, NumberOfSwimmingPools } from "@app/contractor.constant";
import { DataMappingService } from "./data-mapping.service";


@Injectable({
  providedIn: "root",
})
export class UWDataMappingService {
 
  constructor(
    public dataService: DataService,
    public dropDownService: DropdownService,
    public dataMappingService: DataMappingService
  ) {}

  functionsMap = {
    [cReconstrConstr]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPBuilding.cReconstrConstr = dataMappingService.currentForm.get(cReconstrConstr).value;
        }
      }
    ],
    [resAuRepMnufcProcOcup]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
       
          if(flag && resAuRepMnufcProcOcup in dataService.BPBuilding){
           const Buildings = dataService.businessOwners.BPBuilding;
           Buildings.forEach((ele)=>{
            ele.resAuRepMnufcProcOcup = dataMappingService.currentForm.get(resAuRepMnufcProcOcup).value;
           })
          }
        }
    
    ],
    [recentPolCancld]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPGeneralQuestionsMU.recentPolCancld = dataMappingService.currentForm.get(recentPolCancld).value;
        }
      }
    ],
    [otherLocations]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPGeneralQuestionsMU.otherLocations = dataMappingService.currentForm.get(otherLocations).value;
        }
      }
    ],
    [cCompPolicy]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPGeneralQuestionsMU.cCompPolicy = dataMappingService.currentForm.get(cCompPolicy).value;
        }
      }
    ],
    [applicantLawsuit]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
      
          if(flag && applicantLawsuit in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.applicantLawsuit = dataMappingService.currentForm.get(applicantLawsuit).value;
          }
      
      }
    ],
    [cReasons]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        
          if(flag && cReasons in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.cReasons = dataMappingService.currentForm.get(cReasons).value;
          }
       
      }
    ],
    [SwimmingPoolExists]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
      
          if(flag && SwimmingPoolExists in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.SwimmingPoolExists = dataMappingService.currentForm.get(SwimmingPoolExists).value; // need to find and map
              if(dataMappingService.currentForm.get(SwimmingPoolExists).value=="Y" && NumberOfSwimmingPools in dataService.BPBuilding)
              {
                dataService.BPBuilding.NumberOfSwimmingPools='1';
                dataService.BPBuilding.swimmingPool='Y';
              }else if(dataMappingService.currentForm.get(SwimmingPoolExists).value=="N" && NumberOfSwimmingPools in dataService.BPBuilding){
                dataService.BPBuilding.NumberOfSwimmingPools='';
                dataService.BPBuilding.swimmingPool='N';
              }
          }
       
      }
    ],
    [PlayGroundExists]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
       
          if(flag && PlayGroundExists in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.PlayGroundExists = dataMappingService.currentForm.get(PlayGroundExists).value; // need to find and map
          }
      
      }
    ],
    [CRecQues]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
     
          if(flag && CRecQues in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.CRecQues = dataMappingService.currentForm.get(CRecQues).value; // need to find and map
            if(dataMappingService.currentForm.get(CRecQues).value=="Y")
            {
              dataService.BPGeneralQuestionsMU.RecFacilities="Other";
            }
            else
            {
              dataService.BPGeneralQuestionsMU.RecFacilities="";
            }
          }
      
      }
    ],
    [RiseToClaim]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
       
          if(flag && RiseToClaim in dataService.BPGeneralQuestionsMU){
           dataService.BPGeneralQuestionsMU.RiseToClaim = dataMappingService.currentForm.get(RiseToClaim).value; // need to find and map
          }
       
      }
    ],
    [ListOfPolicies]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPGeneralQuestionsMU.ListOfPolicies = "None";
        }
      }
    ],
    cCompPolicyData: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.BPGeneralQuestionsMU.cCompPolicy == 'Y'){
            dataService.BPGeneralQuestionsMU[BPCompanionPolicy] = {
              [CCompPolicyType]: "",
              [CCompPolicyNumberRef]: ""
            }
            dataService.BPGeneralQuestionsMU.BPCompanionPolicy.CCompPolicyType = dataMappingService.currentForm.get(CCompPolicyType).value; // need to find and map
            dataService.BPGeneralQuestionsMU.BPCompanionPolicy.CCompPolicyNumberRef = dataMappingService.currentForm.get(CCompPolicyNumberRef).value; // mapped based on Gopal Comments
          }else{
            dataService.BPGeneralQuestionsMU[BPCompanionPolicy] = undefined;
          }
        }
      }
    ],
    [foreignProducts]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
       
          if(flag && foreignProducts in dataService.BPGeneralQuestionsMU){
             dataService.BPGeneralQuestionsMU.foreignProducts = dataMappingService.currentForm.get(foreignProducts).value; // need to find and map
             if(CDescProducts in dataService.BPGeneralQuestionsMU)
             {
              dataService.BPGeneralQuestionsMU.CDescProducts=(dataMappingService.currentForm.get(foreignProducts).value=='Y')?dataMappingService.currentForm.get(CDescProducts).value:"";
             }
            
          }
      
      }
    ],
    [Nfpa96]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
       
          if(flag && Nfpa96 in dataService.BPGeneralQuestionsMU){
             dataService.BPGeneralQuestionsMU.Nfpa96 = dataMappingService.currentForm.get(Nfpa96).value; // need to find and map
            
          }
       
      }
    ],
    overallIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          dataService.businessOwners.BPIRPMRating[0].BPIRPMRatingLevel[0].OverrideModifier = dataMappingService.overallIRPMFactor;
          dataService.businessOwners.BPIRPMRating[0].BPIRPMRatingLevel[0].TotalModifier = dataMappingService.overallIRPMFactor;
        }
      }
    ],
    GeneralManagementIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors[0].FactorType = dataMappingService.factorType;
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors[0].UnderwriterRiskConcern = "General Management";
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors[0].RiskFactor = "MA-Both Combined-General Management";
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors[0].OverrideReason = dataMappingService.generalManagementIRPMJustification;
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors[0].OverrideValue = dataMappingService.generalManagementIRPMFactor;
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors[0].TotalModifier = dataMappingService.generalManagementIRPMFactor;
        }
      }
    ],
    BuildingFeaturesIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          if(dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length >= 2) {
            while (dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length > 1) { 
              dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.pop();
            }
          }
          
          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.push({
            FactorType: dataMappingService.factorType,
            UnderwriterRiskConcern: "Building Features",
            RiskFactor: "MA-Both Combined-Building Features",
            OverrideReason: dataMappingService.buildingFeaturesIRPMJustification,
            OverrideValue: dataMappingService.buildingFeaturesIRPMFactor,
            TotalModifier: dataMappingService.buildingFeaturesIRPMFactor
          })
        }
      }
    ],
    LocationIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          if(dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length >= 3) {
            while (dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length > 2) { 
              dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.pop();
            }
          }

          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.push({
            FactorType: dataMappingService.factorType,
            UnderwriterRiskConcern: "Location",
            RiskFactor: "MA-Both Combined-Location",
            OverrideReason: dataMappingService.locationIRPMJustification,
            OverrideValue: dataMappingService.locationIRPMFactor,
            TotalModifier: dataMappingService.locationIRPMFactor
          })
        }
      }
    ],
    ProtectionIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          if(dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length >= 4) {
            while (dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length > 3) { 
              dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.pop();
            }
          }

          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.push({
            FactorType: dataMappingService.factorType,
            UnderwriterRiskConcern: "Loss Prevention/Safety Management",
            RiskFactor: "MA-Both Combined-Loss Prevention/Safety Management",
            OverrideReason: dataMappingService.protectionIRPMJustification,
            OverrideValue: dataMappingService.protectionIRPMFactor,
            TotalModifier: dataMappingService.protectionIRPMFactor
          })
        }
      }
    ],
    PremisesAndEquipmentIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          if(dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length >= 5) {
            while (dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length > 4) { 
              dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.pop();
            }
          }

          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.push({
            FactorType: dataMappingService.factorType,
            UnderwriterRiskConcern: "Premises And Equipment",
            RiskFactor: "MA-Both Combined-Premises And Equipment",
            OverrideReason: dataMappingService.premisesAndEquipmentIRPMJustification,
            OverrideValue: dataMappingService.premisesAndEquipmentIRPMFactor,
            TotalModifier: dataMappingService.premisesAndEquipmentIRPMFactor
          })
        }
      }
    ],
    SafetyManagementIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          if(dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length >= 6) {
            while (dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length > 5) { 
              dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.pop();
            }
          }

          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.push({
            FactorType: dataMappingService.factorType,
            UnderwriterRiskConcern: "Safety Management",
            RiskFactor: "MA-Both Combined-Safety Management",
            OverrideReason: dataMappingService.safetyManagementIRPMJustification,
            OverrideValue: dataMappingService.safetyManagementIRPMFactor,
            TotalModifier: dataMappingService.safetyManagementIRPMFactor
          })
        }
      }
    ],
    EmployeesIRPM: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if(flag) {
          if(dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length >= 7) {
            while (dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.length > 6) { 
              dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.pop();
            }
          }

          dataService.businessOwners.BPIRPMRating[0].BPIRPMOverridefactors.push({
            FactorType: dataMappingService.factorType,
            UnderwriterRiskConcern: "Employees",
            RiskFactor: "MA-Both Combined-Employees",
            OverrideReason: dataMappingService.employeesIRPMJustification,
            OverrideValue: dataMappingService.employeesIRPMFactor,
            TotalModifier: dataMappingService.employeesIRPMFactor
          })
        }
      }
    ],

    // add more mappings as necessary
  };

  businessFlagFunctionsMap = {
    [BusinessAttribute.CONTRACTORS]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
      // add more functions as necessary
    ],
    [BusinessAttribute.APARTMENTS]: [
      // add functions for this flag
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.SwimmingPoolExists,
      this.functionsMap.PlayGroundExists,
      this.functionsMap.CRecQues,
      this.functionsMap.RiseToClaim,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
    ],
    [BusinessAttribute.WHOLESALE_RISKS]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.foreignProducts,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
      // add more functions as necessary
    ],
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
      // add more functions as necessary
    ],
    [BusinessAttribute.OFFICE]: [
      // add functions for this flag
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.RiseToClaim,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
  
    ],
    [BusinessAttribute.CONVENIENCE]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
      // add more functions as necessary
    ],
    [BusinessAttribute.MERCANTILE_RISKS]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.foreignProducts,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
      // add more functions as necessary
    ],
    [BusinessAttribute.RESTAURANTS]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
      this.functionsMap.Nfpa96,
      // add more functions as necessary
    ],
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]: [
      this.functionsMap.cReconstrConstr,
      this.functionsMap.resAuRepMnufcProcOcup,
      this.functionsMap.recentPolCancld,
      this.functionsMap.otherLocations,
      this.functionsMap.cCompPolicy,
      this.functionsMap.applicantLawsuit,
      this.functionsMap.cReasons,
      this.functionsMap.ListOfPolicies,
      this.functionsMap.cCompPolicyData,
      this.functionsMap.overallIRPM,
      this.functionsMap.GeneralManagementIRPM,
      this.functionsMap.BuildingFeaturesIRPM,
      this.functionsMap.LocationIRPM,
      this.functionsMap.ProtectionIRPM,
      this.functionsMap.PremisesAndEquipmentIRPM,
      this.functionsMap.SafetyManagementIRPM,
      this.functionsMap.EmployeesIRPM,
 
      // add more functions as necessary
    ],
    // add more mappings as necessary
  };

  // call this function from data mapping service for mapping data
  mapDataBasedOnFlagName = (dataMappingService: DataMappingService, dataService: DataService) => {
    const functionsToExecute = this.businessFlagFunctionsMap[dataMappingService.getBusinessFlag()];
    if (functionsToExecute) {
      functionsToExecute.forEach((functions) => {
        functions.forEach((fn) => {
          fn(true, dataMappingService, dataService);
        });
      });
    }
  }
}
