import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { CBlgExtRepCc, apartmentBldEnd, brbBeutPrsEnhPrfLb, cContraEnhaEndorsement, cCyberOption, cEnhancerPlusEndt, cIsCyberCov, condLiabEndtLmt, enhancerEndorsement, photography, restEndorsement, veterinarProfLiab,hireNonownAutoLiab ,cEpliLimDed, cCyberCovLimit} from '@app/contractor.constant';
import { ComponentService } from '@app/core/services/component.service';
import { DataMappingService } from '@app/core/services/data-mapping.service';
import { DataService } from '@app/core/services/data.service';
import { DeveloperService } from '@app/core/services/developer.service';
import { UiService } from '@app/core/services/ui.service';
import * as contractorConstants from '../../contractor.constant';
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class QuoteSummaryComponent implements OnInit,OnDestroy {

  QuotePolicyNumber: string;
  AnnualPremiumAmount: string;
  LiabilityLimits:string;
  MedicalExpenses:string;
  BuildingCoverage:string;
  BusinessPersonalProperty:string;
  Deductible:string;
  NonOwnedHiredAutoCoverage:string;
  EmploymentPracticesLiability:string;
  CyberLite:string;
  DamagetoPremisesRented:string;
  ContractorEnhancer:boolean;
  HiredAutoCoverage:boolean;
  EmpPracLiability:boolean;
  CyberCoverLimit:boolean;
  ContractorToolsandEquipment:boolean;
  SnowPlowLiability:boolean;
  BlanketAdditionalInsured:boolean;
  WaiverofSubrogation:boolean;
  PrimaryNoncontributory:boolean;
  EnhanceEndorsement:boolean;
  EnhancePlusEndorsement:boolean;
  ApartmentBuildingEndorsement:boolean;
  VeterinarianEndorsement:boolean;
  PhotographyEndorsement:boolean;
  BarbersBeauticianProfessionalCoverage:boolean;
  FuneralProfessionalCoverage:boolean;  
  CondLiabEndtLmt:string;
  cCyberOptionValue:string;
  contractorConstants = contractorConstants;
  cBlgExtRepCcFlag:boolean = false;
  CondLiabEndtLmtFlag:boolean = false;
  CExtRpCcOptions:string;
  RestaurantEndorsement:boolean=false;
  RestaurantFlag:boolean=false;
  dummyArray=["one","two"];
  ProductsCompletedAggregateLimit:string;
  LiabilityGeneralAggregateLimit:string;
  LocationOneBuildings=[];
  LocationTwoBuildings=[];
  LocationThreeBuildings=[];
  zeroDollar='$0';

  constructor(private componentService: ComponentService, public DeveloperService: DeveloperService, public DataService: DataService, public DataMappingService: DataMappingService, public uiService: UiService) {
    this.componentService.currentComponent = 'quoteSummary';
    this.componentService.currentComponentChanged.emit('quoteSummary');
   }

  ngOnInit(): void {
    console.log(this.DeveloperService.findEmptyAttributes(this.DataService.current_business_request));
    this.initializeQuoteSummaryData();
  }

  initializeQuoteSummaryData() {
    this.LiabilityLimits = `${this.returnLiabilityLimit()}`;
    this.ProductsCompletedAggregateLimit=`${this.returnProductsCompletedAggregateLimit()}`;
    this.LiabilityGeneralAggregateLimit=`${this.returnLiabilityGeneralAggregateLimit()}`
    this.MedicalExpenses = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.businessOwners.medicalExpensPerPerson))}`;
    if(this.DataService.BPBuildingCoverage!=null)
    {
      this.BuildingCoverage = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.BPBuildingCoverage.BuildingLimitInsurance))}`;
     }
     else{this.BuildingCoverage='$0';}
    this.BusinessPersonalProperty = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.BPClassifications.BusinssPersonalPropLmt))}`;
    this.Deductible = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.BPBuilding.OptionalPropertyDedu))}`;
    this.NonOwnedHiredAutoCoverage = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.businessOwners.nonownedAutoLiabLmt))}`;
    this.cBlgExtRepCcFlag=contractorConstants.CBlgExtRepCc in this.DataService.businessOwners ? this.DataService.businessOwners.CBlgExtRepCc == 'Y' : false;
    this.EmploymentPracticesLiability = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.businessOwners.cEpliLimDed))}`;
    this.CyberLite = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.businessOwners.cCyberCovLimit))}`;
    this.DamagetoPremisesRented = `${this.DataMappingService.convertToDollarFormat(Number(this.DataService.BPBuilding.dmgPremRentYouLimit))}`;
    this.RestaurantFlag=this.DataMappingService.businessFlags[BusinessAttribute.RESTAURANTS];
    if( CBlgExtRepCc in this.DataService.businessOwners)
   {
    this.CExtRpCcOptions=this.DataService.businessOwners.CExtRpCcOptions;
   }


    
    if( cIsCyberCov in this.DataService.businessOwners)
    {

      this.cCyberOptionValue=this.DataService.businessOwners.cCyberOption
    }

    if (cContraEnhaEndorsement in this.DataService.businessOwners) {
      this.ContractorEnhancer = this.DataService.businessOwners.cContraEnhaEndorsement == 'Y';
    } else {
      this.ContractorEnhancer = false;
    }
    
    if(enhancerEndorsement in this.DataService.businessOwners) {
      this.EnhanceEndorsement = this.DataService.businessOwners.enhancerEndorsement=='Y';
    } else {
      this.EnhanceEndorsement = false;
    }

    if(cEnhancerPlusEndt in this.DataService.businessOwners) {
      this.EnhancePlusEndorsement = this.DataService.businessOwners.cEnhancerPlusEndt=='Y';
    } else {
      this.EnhanceEndorsement = false;
    }

    if(restEndorsement in this.DataService.BPInlineOBJ) {
      this.RestaurantEndorsement = this.DataService.BPInlineOBJ.restEndorsement=='Y';
    } else {
      this.RestaurantEndorsement =false;
    }

    if(veterinarProfLiab in this.DataService.BPBuilding.BPClassifications) {
      this.VeterinarianEndorsement = this.DataService.BPBuilding.BPClassifications.veterinarProfLiab=='Y';
    } else {
      this.VeterinarianEndorsement =false;
    }

    if(photography in this.DataService.BPClassifications)
    { this.PhotographyEndorsement=this.DataService.BPClassifications.photography=='Y';
    } else{
      this.PhotographyEndorsement=false;
    }

    if(brbBeutPrsEnhPrfLb in this.DataService.BPClassifications)
    { this.BarbersBeauticianProfessionalCoverage=this.DataService.BPClassifications.brbBeutPrsEnhPrfLb=='Y';
    } else{
      this.BarbersBeauticianProfessionalCoverage=false;
    }
       

    if(apartmentBldEnd in this.DataService.businessOwners.BPLocations[0].BPInlineOBJ) {
      this.ApartmentBuildingEndorsement = this.DataService.businessOwners.BPLocations[0].BPInlineOBJ.apartmentBldEnd=='Y';
    } else {
      this.ApartmentBuildingEndorsement =false;
    }
    


    if(contractorConstants.BPContractorsEndorsementConst in this.DataService.businessOwners) {
      this.ContractorToolsandEquipment = this.DataService.BPContractorsEndorsement.contrToolsEqpmntCov == 'Y';
      this.BlanketAdditionalInsured = this.DataService.BPContractorsEndorsement.blanketCov == 'Y';
    }

    if(contractorConstants.BPOptionalRTCCoveragesConst in this.DataService.businessOwners) {
      this.WaiverofSubrogation = this.DataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Transfer of Rights of Recovery (Waiver of Subrogation)').length != 0;
      this.PrimaryNoncontributory = this.DataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Primary and Noncontributory').length != 0;
      this.FuneralProfessionalCoverage = this.DataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Funeral Directors Professional Liability').length != 0;
    }

    if((this.uiService.condo69145Flag||this.uiService.condoOffice60999Flag) && this.uiService.condoDOSelectedFlag=='Y') { 
      this.CondLiabEndtLmtFlag=true;
      this.CondLiabEndtLmt=`${this.DataMappingService.convertToDollarFormat(Number(condLiabEndtLmt in this.DataService.BPClassifications ? this.DataService.BPClassifications.condLiabEndtLmt: ''))}`;
    } else {
      this.CondLiabEndtLmtFlag = false;
    }

    if(contractorConstants.snowIceRemoval in this.DataService.BPGeneralQuestionsMU) {
      this.SnowPlowLiability = this.DataService.BPGeneralQuestionsMU.snowIceRemoval == 'Y';
    } else {
      this.SnowPlowLiability = false;
    }
    
    if(!this.uiService.hasRatingErrors) {
      this.QuotePolicyNumber = this.DataService.current_business_response.apiOutput.result.quotePolicy.DisplayPolicyNumber;
      this.uiService.quoteNumber = this.DataService.current_business_response.apiOutput.result.quotePolicy.DisplayPolicyNumber;
      this.AnnualPremiumAmount = this.DataMappingService.convertToDollarFormat(Number(this.DataService.current_business_response.apiOutput.result.quotePolicy.AnnualPremium), false);
    }
    if (hireNonownAutoLiab in this.DataService.businessOwners) {
      this.HiredAutoCoverage = this.DataService.businessOwners.hireNonownAutoLiab == 'Y';
    } else {
      this.HiredAutoCoverage = false;
    }
    if (cEpliLimDed in this.DataService.businessOwners) {
      this.EmpPracLiability = this.DataService.businessOwners.cEpliLimDed !== '';
    } else {
      this.EmpPracLiability = false;
    }
    if (cCyberCovLimit in this.DataService.businessOwners) {
      this.CyberCoverLimit = this.DataService.businessOwners.cCyberCovLimit !== '';
    } else {
      this.CyberCoverLimit = false;
    }
    this.cssHeightCount();
   this.DataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding.forEach((d)=>{
    if(d.locationId==1){
      this.LocationOneBuildings.push(d);
    }else if(d.locationId==2){
      this.LocationTwoBuildings.push(d);
    }else if(d.locationId==3){
      this.LocationThreeBuildings.push(d);
    }
   })

  }
  cssHeightCount(){
    let c=0;
    if(this.HiredAutoCoverage){
      c=c+1;
    }
    if(this.EmpPracLiability)
    {
      c=c+1;
    }
    if(this.CyberCoverLimit)
    {
      c=c+1;
    }
    if(this.cBlgExtRepCcFlag)
    {
      c=c+1;
    }
    if(this.ContractorEnhancer)
    {
      c=c+1;
    }
    if(this.EnhanceEndorsement)
    {
      c=c+1;
    }
    if(this.EnhancePlusEndorsement)
    {
      c=c+1;
    }
    if(this.ApartmentBuildingEndorsement)
    {
      c=c+1;
    }
    if(this.CondLiabEndtLmtFlag)
    {
      c=c+1;
    }
    if(this.uiService.convenience09331Flag || (this.DataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && this.uiService.convenience54136Flag) || this.RestaurantFlag || this.uiService.mercantileRiskBewargeGorceryStoreFlag)
    {
      c=c+1;
    }
    if(this.VeterinarianEndorsement)
    {
      c=c+1;
    }
    if(this.RestaurantEndorsement)
    {
      c=c+1;
    }
    if(this.ContractorToolsandEquipment)
    {
      c=c+1;
    }
    if(this.SnowPlowLiability)
    {
      c=c+1;
    }
    if(this.BarbersBeauticianProfessionalCoverage)
    {
      c=c+1;
    }
    if(this.FuneralProfessionalCoverage)
    {
      c=c+1;
    }
    if(this.PhotographyEndorsement)
    {
      c=c+1;
    }
    if(this.uiService.onBlanketOngoingFlag=='Y' || this.uiService.onBlanketCompletedFlag=='Y')
    {
      c=c+1;
    }
    if(this.PrimaryNoncontributory)
    {
      c=c+1;
    }
    if(this.WaiverofSubrogation)
    {
      c=c+1;
    }
    
  }

  returnLiabilityLimit() {
    return `${this.formatLiabilityLimit(this.DataService.businessOwners.liabMedicalExpensLmt)}/${this.formatLiabilityLimit(this.DataService.businessOwners.prodCompleteOperAggLmt)}`;
  }
  returnProductsCompletedAggregateLimit(){
    return `${this.formatLiabilityLimit(this.DataService.businessOwners.prodCompleteOperAggLmt)}`
  }
  returnLiabilityGeneralAggregateLimit(){
    return `${this.formatLiabilityLimit(this.DataService.businessOwners.liabMedicalExpAggLmt)}`
  }

  formatLiabilityLimit(limit:string) {
    if (Number(limit) >= 1000000) {
      return Math.floor(Number(limit) / 1000000) + "MM";
    } else {
      return `${this.DataMappingService.convertToDollarFormat(Number(limit))}`;
    }
  }
  
  proceedToCoverall() {
    if(this.QuotePolicyNumber) {
      if(this.uiService.referralIndicator == "Y") {
        this.uiService.quotedWithReferrals = true;
      } else {
        let eType = this.DataService.current_business_response.apiOutput.result.quotePolicy.EntityType;
        let eRef = this.DataService.current_business_response.apiOutput.result.quotePolicy.EntityReference;
        let edisplayName = this.DataService.current_business_response.apiOutput.result.quotePolicy.DisplayPolicyNumber;
        console.log(eType, eRef, edisplayName);
        console.log(environment.coverallDashboardURL +"?entityType="+ eType +"&entityReference="+ eRef +"&entityDisplayName="+ edisplayName +"&authtoken="+ encodeURIComponent(sessionStorage.getItem("coverallToken")), "_blank");
        window.open(environment.coverallDashboardURL +"?entityType="+ eType +"&entityReference="+ eRef +"&entityDisplayName="+ edisplayName +"&authtoken="+ encodeURIComponent(sessionStorage.getItem("coverallToken")), "_blank");
        sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
        location.reload();
      }
    }
  }

  ngOnDestroy(){
    this.uiService.uniqueId="";
  }
}