import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

/**
 * CustomWindow includes Google Tag Manager custom functions MAPFRE implements.
 */
export interface CustomWindow extends Window {
  /**
   * stepView is a custom function to log page changes on SPAs.
   * Pass a unique ID per page.
   * @param stepId unique id for the step
   */
  stepView: (stepId: string) => void;
}

/**
 * Helps setup Google Tag Manager (GTM) for this Angular app.
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  customWindow?: CustomWindow | null;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.customWindow = this.document.defaultView as any;
  }

  private getCurrentRouteId(): string {
    let route: ActivatedRoute = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data['routeId'] ?? 'error-routeId-not-defined';
  }

  /**
   * Custom function that tells GTM that the page has changed.
   * This is required for Angular/SPA applications.
   * @param stepId of app step
   */
  stepView(stepId: string): void {
    if (typeof this.customWindow?.stepView === 'function') {
      this.customWindow.stepView(stepId);
    }
  }

  /**
   * Subscribes to all route changes and calls custom GTM function stepView
   * for each route change NavigationEnd event.
   */
  setupGoogleTagManagerPageEvents(): void {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        this.stepView(this.getCurrentRouteId());
      });
  }
}
